﻿<template>
	<div
		class="flex-nowrap call-toolbar-container"
		:class="{
			'justify-center call-toolbar-container-fullscreen': fullScreen,
			'justify-content-between': !fullScreen,
		}">
		<div class="d-flex flex-column" id="left-control" v-if="!fullScreen">
			<div id="meeting-subject" class="text-white common-text-16 text-left meeting-title">
				{{ meetingSubject }}
			</div>

			<div class="d-flex flex-row">
				<button
					type="button"
					id="btn-invite"
					class="btn-left btn-invite"
					:class="{ active: invite, hover: inviteHover }"
					@pointerenter="inviteHover = true"
					@pointerleave="inviteHover = false"
					@click="toggleInvite">
					<svg class="svg-inline--fa" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<g opacity="0.8">
							<path
								d="M11.5567 5.72451L11.6451 5.8129L11.7335 5.72451L12.5347 4.92327L12.6231 4.83488L12.5347 4.7465L8.70023 0.912001L8.61184 0.823612L8.52345 0.912001L4.68896 4.7465L4.60057 4.83488L4.68896 4.92327L5.49019 5.72451L5.57858 5.8129L5.66697 5.72451L8.61184 2.77964L11.5567 5.72451Z"
								fill="currentColor"
								stroke="currentColor"
								stroke-width="1" />
							<path
								d="M8.03906 1.67678H7.91406V1.80178V13.8203V13.9453H8.03906H9.18369H9.30869V13.8203V1.80178V1.67678H9.18369H8.03906Z"
								fill="currentColor"
								stroke="currentColor"
								stroke-width="1" />
							<path
								d="M4.55888 7.38992V7.26492H4.43388H2.71694C1.67497 7.26492 0.875 8.06489 0.875 9.10685V15.9746C0.875 17.0166 1.67497 17.8165 2.71694 17.8165H14.1632C15.2052 17.8165 16.0051 17.0166 16.0051 15.9746V9.10685C16.0051 8.06489 15.2052 7.26492 14.1632 7.26492H12.4463H12.3213V7.38992V8.53454V8.65954H12.4463H14.1632C14.3065 8.65954 14.4162 8.70676 14.4897 8.78032C14.5633 8.85387 14.6105 8.96357 14.6105 9.10685V15.9746C14.6105 16.1179 14.5633 16.2276 14.4897 16.3011C14.4162 16.3747 14.3065 16.4219 14.1632 16.4219H2.71694C2.57365 16.4219 2.46396 16.3747 2.3904 16.3011C2.31684 16.2276 2.26963 16.1179 2.26963 15.9746V9.10685C2.26963 8.96357 2.31684 8.85387 2.3904 8.78032C2.46396 8.70676 2.57365 8.65954 2.71694 8.65954H4.43388H4.55888V8.53454V7.38992Z"
								fill="currentColor"
								stroke="currentColor"
								stroke-width="1" />
						</g>
					</svg>
					<div class="tooltip-container row justify-center far-left">
						<div class="tooltip col-auto">{{ meetingHandler.canInviteViaSms ? "Email " : "" }}Invite</div>
					</div>
				</button>
				<button
					v-if="meetingHandler.canInviteViaSms"
					type="button"
					id="btn-invite-sms"
					class="btn-middle btn-invite"
					:class="{ active: inviteSMS, hover: inviteSMSHover }"
					@pointerenter="inviteSMSHover = true"
					@pointerleave="inviteSMSHover = false"
					@click="toggleInviteSMS">
					<div class="d-flex icon"><i class="fas fa-comment-dots"></i></div>
					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">Text Invite</div>
					</div>
				</button>
				<button
					type="button"
					id="btn-report-issue"
					class="btn-middle"
					:class="{ hover: reportIssueHover, 'ipad-svg-font-size': useHelpers().isIpad() }"
					@pointerenter="reportIssueHover = true"
					@pointerleave="reportIssueHover = false"
					@click="reportIssue">
					<img src="../assets/img/warning.svg" alt="Report Issue" />

					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">Report Issue</div>
					</div>
				</button>
				<button
					type="button"
					id="btn-copy-link"
					class="btn-right"
					:class="{ hover: copyLinkHover, active: linkCopied }"
					@pointerenter="copyLinkHover = true"
					@pointerleave="copyLinkHover = false"
					@click="copyLink()">
					<svg class="svg-inline--fa" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M10.5846 14H5.33462C4.36587 14 3.58462 13.2188 3.58462 12.25V3H1.33462C0.897116 3 0.584616 3.34375 0.584616 3.75V15.25C0.584616 15.6875 0.897116 16 1.33462 16H9.83462C10.2409 16 10.5846 15.6875 10.5846 15.25V14ZM10.5846 3.25V0H5.33462C4.89712 0 4.58462 0.34375 4.58462 0.75V12.25C4.58462 12.6875 4.89712 13 5.33462 13H13.8346C14.2409 13 14.5846 12.6875 14.5846 12.25V4H11.3346C10.8971 4 10.5846 3.6875 10.5846 3.25ZM14.3346 2.28125L12.3034 0.25C12.1471 0.09375 11.9596 0 11.7721 0H11.5846V3H14.5846V2.8125C14.5846 2.625 14.4909 2.4375 14.3346 2.28125Z"
							fill="currentColor" />
					</svg>
					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">Copy Link</div>
					</div>
					<div class="tooltip-container row justify-center" v-show="linkCopied">
						<div class="tooltip-persistant col-auto">Link Copied!</div>
					</div>
				</button>
			</div>
		</div>

		<div class="d-flex video-controls-container">
			<div class="btn-control-my-world btn-desktop" v-if="!fullScreen">
				<button
					v-if="!botEnabled"
					type="button"
					id="btn-my-world"
					class="panel-button my-world"
					:class="{ active: myworld, hover: myworldHover, 'ipad-svg-font-size': useHelpers().isIpad() }"
					@click="toggleMyWorld"
					@pointerenter="myworldHover = true"
					@pointerleave="myworldHover = false">
					<svg width="30" height="30" viewBox="0 0 30 30" fill="currentColor">
						<path
							d="M15.0052 9.13821e-07C12.0381 -0.00103457 9.13725 0.877952 6.66972 2.52577C4.20218 4.1736 2.27878 6.51622 1.14282 9.25733C0.00685227 11.9984 -0.290639 15.0149 0.287972 17.9251C0.866583 20.8353 2.2953 23.5085 4.3934 25.6066C6.49151 27.7047 9.16473 29.1334 12.0749 29.712C14.9851 30.2906 18.0016 29.9931 20.7427 28.8572C23.4838 27.7212 25.8264 25.7978 27.4742 23.3303C29.1221 20.8627 30.001 17.9619 30 14.9948C29.9958 11.0192 28.4147 7.20762 25.6035 4.39646C22.7924 1.58529 18.9808 0.00415875 15.0052 9.13821e-07ZM2.02095 15.0052C2.02189 14.0363 2.13076 13.0705 2.34556 12.1257C2.41885 12.1675 12.5812 17.1099 13.3717 27.8848C10.2364 27.4872 7.35367 25.9597 5.26404 23.5886C3.17441 21.2175 2.02133 18.1657 2.02095 15.0052ZM23.9319 24.4293V23.5288C23.9319 22.7748 23.6324 22.0517 23.0993 21.5185C22.5661 20.9854 21.843 20.6859 21.089 20.6859C20.7669 20.6859 20.4579 20.5579 20.2301 20.3301C20.0023 20.1023 19.8743 19.7933 19.8743 19.4712V16.6283C19.8743 16.4151 19.8324 16.2041 19.7508 16.0072C19.6692 15.8102 19.5497 15.6313 19.399 15.4806C19.2483 15.3299 19.0693 15.2103 18.8724 15.1288C18.6755 15.0472 18.4645 15.0052 18.2513 15.0052H11.7592C11.0752 15.0057 10.414 14.7591 9.89745 14.3108C9.38085 13.8625 9.04356 13.2427 8.94765 12.5654H11.7592C11.9723 12.5654 12.1834 12.5235 12.3803 12.4419C12.5772 12.3603 12.7561 12.2408 12.9068 12.0901C13.0575 11.9394 13.1771 11.7604 13.2587 11.5635C13.3402 11.3666 13.3822 11.1555 13.3822 10.9424V9.79058C13.3822 9.23515 13.6028 8.70247 13.9956 8.30972C14.3883 7.91698 14.921 7.69633 15.4764 7.69633H17.0471C17.8002 7.69634 18.5225 7.39753 19.0555 6.86551C19.5885 6.33349 19.8887 5.61172 19.8901 4.85864V2.96859C21.9069 3.78292 23.6867 5.09102 25.066 6.77273C26.4453 8.45443 27.3799 10.4558 27.7838 12.5929C28.1878 14.7301 28.0482 16.9345 27.3779 19.0036C26.7076 21.0728 25.5281 22.9403 23.9476 24.4345L23.9319 24.4293Z" />
					</svg>
					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">
							{{ myworld ? "Close MyWorld" : "Open MyWorld" }}
						</div>
					</div>
				</button>

				<button
					v-if="botEnabled && canStartBot"
					type="button"
					id="btn-invite-bot"
					class="panel-button my-world bot"
					:class="{ 
						hover: inviteBotHover, 
						'ipad-svg-font-size': useHelpers().isIpad(), 
						'joined': meetingHandler.botConnected, 
						'joining': meetingHandler.botConnecting 
					}"
					style="border-radius:24px;overflow: hidden;background-color:#eee;"
					@click="inviteLiveSwitchBot"
					:disabled="meetingHandler.botConnecting && !meetingHandler.botConnected"
					@pointerenter="inviteBotHover = true"
					@pointerleave="inviteBotHover = false">
					<img style="background-color: #eee;padding:24px 0px 0px 0px" src="../assets/img/larry-the-lab.png" :alt="meetingHandler.botConnected ? 'Larry is active and working!' : 'Click to invite Larry to the call'" />
					<!--<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">{{ meetingHandler.botConnected ? 'Larry is active and working!' : 'Click to invite Larry to the call' }}</div>
					</div>-->
				</button>
			</div>

			<div class="video-controls">
				<button
					type="button"
					id="btn-more-mobile"
					class="panel-button btn-mobile"
					:class="{ active: menu, hover: menuHover }"
					@click="toggleMoreMenu"
					@pointerenter=""
					@pointerleave="">
					<i class="fas fa-ellipsis-h"></i>
					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto" v-if="!menu">More</div>
					</div>
				</button>
				<button
					type="button"
					id="btn-audio"
					class="panel-button btn-audio"
					:class="{
						muted: meetingHandler.audioMuted,
						disabled: meetingHandler.audioDisabled || !meetingHandler.permissionsGranted,
						hover: microHover,
					}"
					@pointerdown="pushToTalk"
					@pointerup="releaseToMute"
					@click="toggleAudio"
					@pointerenter="microHover = true"
					@pointerleave="microHover = false">
					<div v-show="!meetingHandler.audioMuted">
						<i class="fas fa-microphone"></i>
					</div>
					<div v-show="meetingHandler.audioMuted">
						<i class="fas fa-microphone-slash"></i>
					</div>

					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">
							{{
								meetingHandler.permissionsGranted
									? meetingHandler.audioMuted
										? meetingHandler.audioDisabled
											? "Unmute Disabled"
											: "Unmute"
										: "Mute"
									: "Permission Denied"
							}}
						</div>
					</div>
				</button>
				<button
					type="button"
					id="btn-video"
					class="panel-button btn-video"
					:class="{
						muted: meetingHandler.videoMuted,
						disabled: meetingHandler.videoDisabled || !meetingHandler.permissionsGranted || meetingHandler.carMode,
						hover: videoHover,
					}"
					@click="toggleVideo"
					@pointerenter="videoHover = true"
					@pointerleave="videoHover = false">
					<div v-show="!meetingHandler.videoMuted">
						<i class="fas fa-video"></i>
					</div>
					<div v-show="meetingHandler.videoMuted">
						<i class="fas fa-video-slash"></i>
					</div>
					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">
							{{
								meetingHandler.permissionsGranted
									? meetingHandler.videoMuted
										? meetingHandler.videoDisabled
											? "Video Disabled"
											: "Video On"
										: "Video Off"
									: "Permission Denied"
							}}
						</div>
					</div>
				</button>
				<button
					v-if="blurEnabled"
					type="button"
					id="btn-video-blur"
					class="panel-button btn-video"
					:class="{
						active: props.meetingHandler.backgroundMode == 'blur',
						blurred: props.meetingHandler.backgroundMode == 'blur',
						hover: videoBlurHover,
					}"
					@click="props.meetingHandler.toggleBlur()"
					@pointerenter="videoBlurHover = true"
					@pointerleave="videoBlurHover = false">
					<div v-show="props.meetingHandler.backgroundMode !== 'blur'">
						<i class="icon-user-background-blur" />
					</div>
					<div v-show="props.meetingHandler.backgroundMode == 'blur'">
						<i class="icon-user-background-default" />
					</div>
					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">
							{{ props.meetingHandler.backgroundMode == 'blur' ? "Remove Background Blur" : "Blur Background" }}
						</div>
					</div>
				</button>
				<button
					v-if="blurEnabled && !useHelpers().isMobileBrowser()"
					type="button"
					id="btn-video-virtual"
					class="panel-button btn-video"
					:class="{
						active: props.meetingHandler.backgroundMode == 'virtual',
						blurred: props.meetingHandler.backgroundMode == 'virtual',
						hover: virtualBackgroundEnabledHover,
					}"
					@click="props.meetingHandler.toggleVirtualBackground()"
					@pointerenter="virtualBackgroundEnabledHover = true"
					@pointerleave="virtualBackgroundEnabledHover = false">
					<div v-show="props.meetingHandler.backgroundMode !== 'virtual'">
						<i class="fa-regular fa-image" />
					</div>
					<div v-show="props.meetingHandler.backgroundMode == 'virtual'">
						<i class="icon-user-background-default" />
					</div>
					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">
							{{ props.meetingHandler.backgroundMode == 'virtual' ? "Remove Virtual Background" : "Enable Virtual Background" }}
						</div>
					</div>
				</button>
				<button
					type="button"
					id="btn-screen-share"
					class="panel-button btn-screen-share"
					:class="{
						active: meetingHandler.localScreenSharing,
						hover: sharingHover,
						disabled: disableShareScreen,
					}"
					v-if="(props.forceScreenShareVisible || (!useHelpers().isIpad() && !useHelpers().isMobileBrowser()))"
					@click="toggleScreenShare"
					@pointerenter="sharingHover = true"
					@pointerleave="sharingHover = false">
					<div>
						<i class="fas fa-desktop"></i>
					</div>
					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">
							{{ screenShareTooltip }}
						</div>
					</div>
				</button>
				<button
					type="button"
					id="btn-chat-mobile"
					class="panel-button btn-mobile btn-chat"
					:class="{ active: meetingHandler.chatOpen, hover: chatHover }"
					@pointerenter="chatHover = true"
					@pointerleave="chatHover = false"
					@click="toggleChat">
					<i class="fas fa-comments"></i>
					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">Chat</div>
					</div>
					<div v-if="meetingHandler.meeting.chat?.isError" class="chat-count chat-count-mobile"><i class="fa fa-warning"></i></div>
					<div v-else-if="hasAnyNewMessage" class="chat-count chat-count-mobile">{{ newMessageCount }}</div>
				</button>
				<button
					type="button"
					id="btn-users-mobile"
					class="panel-button btn-mobile"
					:class="{ active: meetingHandler.usersOpen, hover: usersHover }"
					@pointerenter="usersHover = true"
					@pointerleave="usersHover = false"
					@click="toggleUsers">
					<i class="fas fa-user-friends"></i>
					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">Users</div>
					</div>
					<div v-if="meetingHandler.meeting.attendees?.isError" class="user-count user-count-error"><i class='fa fa-warning danger' /></div>
					<div v-else class="user-count">{{ meetingHandler.userCount }}</div>
				</button>
				<button
					type="button"
					id="btn-end-call-mobile"
					class="panel-button btn-mobile btn-end-call"
					:class="{ hover: leaveCallHover }"
					@click="leaveCall()"
					@pointerenter="leaveCallHover = true"
					@pointerleave="leaveCallHover = false">
					<i class="fas fa-phone-slash"></i>
					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">End Call</div>
					</div>
				</button>
				<button
					type="button"
					id="btn-recording"
					class="panel-button optional-btn btn-desktop btn-record"
					:class="{
						active: meetingHandler.isRecording,
						hover: recordingHover,
						disabled: isFreeAccount,
						'ipad-svg-font-size': useHelpers().isIpad(),
					}"
					@click="toggleRecording"
					@pointerenter="recordingHover = true"
					@pointerleave="recordingHover = false"
					v-if="canRecord">
					<svg xmlns="http://www.w3.org/2000/svg" class="svg-inline--fa" viewBox="0 0 27 11" fill="none">
						<path
							d="M3.24301 0.149864C4.76057 0.149864 5.87795 0.414623 6.59515 0.944142C7.32275 1.47366 7.68655 2.27793 7.68655 3.35695C7.68655 3.8465 7.5878 4.27611 7.39031 4.64578C7.20322 5.00545 6.94856 5.31517 6.62634 5.57493C6.31451 5.82471 5.97669 6.02952 5.6129 6.18937L8.88708 10.8501H6.26773L3.6172 6.74387H2.3543V10.8501H0V0.149864H3.24301ZM3.0715 2.00817H2.3543V4.90055H3.11828C3.89784 4.90055 4.45394 4.77566 4.78655 4.52589C5.12956 4.27611 5.30107 3.90645 5.30107 3.41689C5.30107 2.90736 5.11917 2.54768 4.75537 2.33787C4.40197 2.11807 3.84068 2.00817 3.0715 2.00817Z"
							fill="currentColor" />
						<path
							d="M16.7008 10.8501H10.2927V0.149864H16.7008V2.00817H12.647V4.36104H16.4202V6.21935H12.647V8.97684H16.7008V10.8501Z"
							fill="currentColor" />
						<path
							d="M23.7414 1.88828C22.8371 1.88828 22.1459 2.21299 21.6677 2.8624C21.1896 3.51181 20.9505 4.401 20.9505 5.52997C20.9505 6.66894 21.1688 7.55313 21.6054 8.18256C22.0523 8.802 22.7643 9.11172 23.7414 9.11172C24.1987 9.11172 24.6509 9.06176 25.0978 8.96185C25.5552 8.86195 26.0489 8.72207 26.579 8.54223V10.4455C26.0905 10.6353 25.6072 10.7752 25.129 10.8651C24.6509 10.955 24.1156 11 23.5231 11C22.3798 11 21.4339 10.7752 20.6855 10.3256C19.9475 9.86603 19.4018 9.22661 19.0484 8.40736C18.695 7.57811 18.5183 6.61399 18.5183 5.51499C18.5183 4.43597 18.721 3.48184 19.1264 2.65259C19.5317 1.82334 20.119 1.17393 20.8882 0.70436C21.6677 0.234786 22.6188 0 23.7414 0C24.2923 0 24.8432 0.0699364 25.3941 0.209809C25.9554 0.339691 26.4907 0.519528 27 0.749319L26.236 2.59264C25.8203 2.40282 25.3993 2.23797 24.9731 2.09809C24.5573 1.95822 24.1468 1.88828 23.7414 1.88828Z"
							fill="currentColor" />
					</svg>
					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">
							{{
								isFreeAccount
									? "Premium Feature"
									: meetingHandler.isRecording
									? "Stop Recording"
									: "Record"
							}}
						</div>
					</div>
				</button>
				<button
					type="button"
					id="btn-raise-hand"
					class="panel-button btn-desktop btn-raise-hand"
					:class="{ active: meetingHandler.handRaised, hover: handHover }"
					@click="toggleRaiseHand"
					@pointerenter="handHover = true"
					@pointerleave="handHover = false">
					<i class="fas fa-hand-paper"></i>
					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">
							{{ meetingHandler.handRaised ? "Lower Hand" : "Raise Hand" }}
						</div>
					</div>
				</button>
			</div>

			<div class="btn-control-leave btn-desktop">
				<button
					type="button"
					id="btn-end-call"
					class="panel-button leave-call"
					:class="{ hover: leaveCallHover }"
					@click="leaveCall()"
					@pointerenter="leaveCallHover = true"
					@pointerleave="leaveCallHover = false">
					<i class="fas fa-phone-slash"></i>
					<div class="orbit"></div>
					<div class="tooltip-container row justify-center">
						<div class="tooltip col-auto">Leave Call</div>
					</div>
				</button>
			</div>
		</div>

		<div class="d-flex" id="right-control" v-if="!fullScreen">
			<button
				type="button"
				id="btn-chat"
				class="btn-left btn-left-lg btn-chat"
				:class="{ active: meetingHandler.chatOpen, hover: chatHover }"
				@pointerenter="chatHover = true"
				@pointerleave="chatHover = false"
				@click="toggleChat">
				<i class="fas fa-comments"></i>
				<div class="tooltip-container row justify-center">
					<div class="tooltip col-auto">Chat</div>
				</div>
				<div class="chat-count" v-if="meetingHandler.meeting.chat?.isError">
					<i class="fa fa-warning" />
				</div>
				<div class="chat-count" v-else-if="hasAnyNewMessage && meetingHandler.chatEnabled">
					{{ newMessageCount }}
				</div>				
			</button>
			<button
				type="button"
				id="btn-users"
				class="btn-right btn-right-lg btn-users"
				:class="{ active: meetingHandler.usersOpen, hover: usersHover }"
				@pointerenter="usersHover = true"
				@pointerleave="usersHover = false"
				@click="toggleUsers">
				<i class="fas fa-user-friends"></i>
				<div class="tooltip-container row justify-center">
					<div class="tooltip col-auto">Users</div>
				</div>
				<div v-if="meetingHandler.meeting.attendees?.isError" class="user-count user-count-error"><i class='fa fa-warning danger' /></div>
				<div v-else class="user-count">{{ meetingHandler.userCount }}</div>
			</button>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { ref, type PropType, onMounted, inject } from "vue";
	import useEventBus from "@/composables/useEventBus";
	import { hasAnyNewMessage, newMessageCount } from "@/composables/useChat";
	import type MeetingHandler from "@/classes/MeetingHandler";
	import { computed } from "@vue/reactivity";
	import useHelpers from "@/composables/useHelpers";
	import type { IChannelService } from "@/classes/ChannelService";
	import { channelServiceKey } from "@/composables/injectKeys";
	import { blurEnabled } from "@/composables/useLocalStorage";

	const channelService = inject(channelServiceKey) as IChannelService;
	const microHover = ref(false);
	const videoHover = ref(false);
	const videoBlurHover = ref(false);
	const virtualBackgroundEnabledHover = ref(false);
	const handHover = ref(false);
	const sharingHover = ref(false);
	const recordingHover = ref(false);
	const menu = ref(false);
	const menuHover = ref(false);
	const myworld = ref(false);
	const myworldHover = ref(false);
	const bot = ref(false);
	const inviteBotHover = ref(false);
	const leaveCallHover = ref(false);
	const chatHover = ref(false);
	const usersHover = ref(false);
	const invite = ref(false);
	const inviteHover = ref(false);
	const inviteSMS = ref(false);
	const inviteSMSHover = ref(false);
	const reportIssueHover = ref(false);
	const copyLinkHover = ref(false);
	const linkCopied = ref(false);
	const backgroundBlurred = ref(false);
	const virtualBackgroundEnabled = ref(false);

	const props = defineProps({
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
		meetingSubject: {
			type: String,
			required: true,
		},
		fullScreen: {
			type: Boolean,
			required: false,
		},
		botEnabled: {
			type: Boolean,
			required: false,
		},
		localMediaBlurred: {
			type: Boolean,
		},
		forceScreenShareVisible: {
			type: Boolean
		}
	});

	onMounted(() => {
		backgroundBlurred.value = props.localMediaBlurred;
		useEventBus().onEvent("toggle-invite", (newValue: boolean | undefined) => {
			if (newValue != undefined) {
				invite.value = newValue;
			} else {
				invite.value = !invite.value;
			}
		});

		useEventBus().onEvent("toggle-invite-sms", (newValue: boolean | undefined) => {
			if (newValue != undefined) {
				inviteSMS.value = newValue;
			} else {
				inviteSMS.value = !inviteSMS.value;
			}
		});

		useEventBus().onEvent("toggle-local-my-world", (open: boolean) => {
			myworld.value = open;
		});

		useEventBus().onEvent("close-more-dialog", () => {
			menu.value = false;
		});

		useEventBus().onEvent("video-blur-updated", (videoBlurred: boolean) => {
			backgroundBlurred.value = videoBlurred;
		});

		useEventBus().emitEvent("bot-save", () => {
		});

		useEventBus().emitEvent("bot-added", () => {
			
		});

		useEventBus().emitEvent("bot-removed", () => {
			
		});
	});

	function toggleInvite() {
		useEventBus().emitEvent("toggle-invite", !invite.value);
	}

	function toggleInviteSMS() {
		useEventBus().emitEvent("toggle-invite-sms", !inviteSMS.value);
	}

	const canRecord = computed(() => props.meetingHandler?.role === "HOST");
	const canStartBot = computed(() => props.meetingHandler?.canStartBot);
	const disableShareScreen = computed(() => props.meetingHandler?.canScreenShare !== true);
	const isFreeAccount = computed(() => props?.meetingHandler.isFreeAccount);

	const screenShareTooltip = computed(() => {

		if (!props.meetingHandler.meeting.originDisplayManager?.isStarted) {
			return "Screen share unavailable. Please refresh your browser and try again.";
		}
		if (!props.meetingHandler) {
			return "Screen Share";
		}
		if (props.meetingHandler.remoteScreenSharing) {
			return "Screen Share in Use";
		}
		if (!props.meetingHandler.canScreenShare) {
			return "Screen Share Disabled";
		}
		if (props.meetingHandler.localScreenSharing) {
			return "Stop Screen Share";
		}
		return "Screen Share";
	});

	async function toggleMyWorld() {
		await props.meetingHandler.openMyWorld(props.meetingHandler.localAttendeeId);
	}

	async function inviteLiveSwitchBot() {
		if (!props.botEnabled) {
			return;
		}
		useEventBus().emitEvent("bot-open");
	}

	function toggleMoreMenu() {
		menu.value = !menu.value;

		if (menu) {
			useEventBus().emitEvent("more-dialog");
		}
	}

	async function toggleAudio() {
		// if (useHelpers().isMobileBrowser()) {
		// 	return;
		// }

		await props.meetingHandler.toggleLocalAudio();
	}

	async function pushToTalk() {
		return;

		if (!useHelpers().isMobileBrowser()) {
			return;
		}

		await props.meetingHandler.toggleLocalAudio();
	}

	async function releaseToMute() {
		return;

		if (!useHelpers().isMobileBrowser()) {
			return;
		}

		await props.meetingHandler.toggleLocalAudio();

		if (!props.meetingHandler.localAttendee.isAudioMuted) {
			await props.meetingHandler.toggleLocalAudio();
		}
	}

	async function toggleVideo() {
		if(props.meetingHandler.carMode) {
			return;
		}
		await props.meetingHandler.toggleLocalVideo();
	}

	async function toggleScreenShare() {
		if (!props.meetingHandler.canScreenShare || disableShareScreen.value || !props.meetingHandler.meeting.originDisplayManager.isStarted) {
			return;
		}

		await props.meetingHandler.shareScreen();
	}

	function toggleChat() {
		props.meetingHandler.toggleChat();
	}

	function toggleRecording() {
		props.meetingHandler.toggleRecording();
	}

	function toggleRaiseHand() {
		props.meetingHandler.raiseHand();
	}

	function toggleUsers() {
		props.meetingHandler.toggleUsers();
	}

	async function leaveCall() {
		await props.meetingHandler.endCall("EndCallButton");
	}

	function copyLink() {
		linkCopied.value = true;
		props.meetingHandler.copyMeetingLink();

		setTimeout(() => {
			linkCopied.value = false;
		}, 2000);
	}

	function reportIssue() {
		useEventBus().emitEvent("toggle-report-issue");
	}
</script>

<style lang="scss" scoped>
	$button-distance: 16px;

	.call-toolbar-container {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 1em;
		display: flex;
		align-items: center;
		z-index: 1000;
	}

	.call-toolbar-container-fullscreen {
		z-index: 1 !important;
	}

	#left-control {
		flex-wrap: wrap;
		flex: 1;
		overflow-wrap: anywhere;
	}

	#right-control {
		flex: 1;
		justify-content: flex-end;
	}

	#meeting-subject {
		max-height: 24px;
		overflow: hidden;
	}

	.meeting-title {
		flex: 1 0 100%;
		margin-bottom: 8px;
		font-family: "Inter_Regular";
	}

	#btn-recording svg {
		max-width: 80%;
	}

	.btn-control-my-world {
		display: flex;
		align-items: center;
		margin-right: 15px;
	}

	.btn-control-leave {
		display: flex;
		align-items: center;
		margin-left: 15px;
	}

	.btn-end-call {
		background-color: #ff715b !important;
	}

	.video-controls {
		background: #00000080;
		border-radius: 14px;
		padding: 10px 7px;
		display: flex;
		text-align: center;
	}

	.disabled {
		color: grey !important;
	}

	button.btn-left,
	button.btn-middle,
	button.btn-right {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		padding-top: 3px;
		color: white;
		background: #1b1e26;
		width: 40px;
		height: 40px;
		border: none;
		transition: 0.2s background;
		cursor: pointer;

		&:hover {
			background: #3a455e;
		}
		&.active {
			background: white;
			color: #2b56ab;
			transition: 0.05s all;
		}

		&:active {
			color: #346ee0;
			background: white;
		}
		.tooltip-container {
			position: absolute;
			left: -47px;
			width: 140px;
			top: -27px;
			pointer-events: none;
			user-select: none;
			z-index: 1;
			.tooltip,
			.tooltip-persistant {
				background: #0f1218;
				padding: 2px 8px;
				border-radius: 10px;

				color: white;
				opacity: 0;
				transition: 0.2s display;
			}

			.tooltip-persistant {
				opacity: 1;
				font-size: 0.7rem;
			}
		}
		&.hover .tooltip {
			opacity: 1;
		}
	}
	button.btn-left {
		border-radius: 6px 0 0 6px;
	}

	button.btn-left .tooltip-container.far-left {
		left: -35px;
	}

	button.btn-middle {
		border-left: 1px solid #343b4a;
		border-right: 1px solid #343b4a;
	}

	button.btn-right {
		border-radius: 0 6px 6px 0;
	}

	.btn-left-lg,
	.btn-right-lg {
		height: 52px !important;
		width: 56px !important;
	}

	button.panel-button {
		position: relative;
		background: rgba($color: #000000, $alpha: 0.5);
		margin: 4px calc($button-distance / 2);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 19px;
		color: white;
		border: none;
		width: 46px;
		cursor: pointer;
		height: 46px;
		border-radius: 50%;
		transition: 0.2s all;
		&.btn-mobile {
			display: none;
		}

		.q-icon {
			transition: 0.2s all;
		}

		.tooltip-container {
			position: absolute;
			pointer-events: none;
			user-select: none;
			left: -45px;
			width: 160px;
			top: -27px;
			z-index: 1;

			.tooltip {
				background: #0f1218;
				padding: 2px 8px;
				border-radius: 10px;
				color: white;
				opacity: 0;
				transition: 0.2s opacity;
			}
		}

		&.hover .tooltip {
			opacity: 1;
		}

		&.leave-call {
			border-radius: 10px;
			background: #ff715b;
			width: 55px;

			&:hover {
				background: #b5412f;
			}
		}

		&.bot {
			
		}

		&.bot.hover {
			border: 0px solid #ffd279;
		}

		&.bot.joined {
			border: 3px solid limegreen;
		}

		@keyframes changewidth {
			from {
				border-width: 0px;
			}

			to {
				border-width: 3px;
			}
		}
		&.bot.joining{
			animation-duration: 1s;
			animation-name: changewidth;
			animation-iteration-count: infinite;
			animation-direction: alternate;
			
			border: 3px solid #ffd279;
		}
		&.my-world.bot {
			width:48px;
			height:48px;
		}

		&.my-world {
			width: 60px;
			height: 60px;
			background: #346ee0;

			.tooltip-container {
				left: -40px;
			}

			&:hover {
				background: #2b56ab;
			}

			&:active {
				background: #2b56ab;
			}

			.orbit {
				position: absolute;
				width: 68px;
				height: 68px;
				border: 2px solid transparent;
				border-radius: 50%;
				top: -4px;
				left: -4px;

				&:hover {
					border: 2px solid #65b5ff40;
				}
			}

			&.active {
				background: white;
				color: #2b56ab;
				transition: 0.05s all;
			}
		}

		&:hover {
			background: #3a455e;

			&.alt {
				background: #dfe1ef;
				color: #346ee0;
			}
		}

		&.active {
			color: #436dd9;
			transition: 0.05s all;

			&.alt {
				background: white;
				color: #346ee0;
			}
		}

		&.muted {
			color: #ff715b;
		}

		&.blurred {
			color: #346ee0 !important;
		}

		&.active {
			background: #dfe1ef;
		}
	}
	.panel-menu {
		position: absolute;
		text-align: left;
		top: -245px;
		left: -50px;
		width: 145px;
		color: white;
		border-radius: 10px;
		padding: 12px 0;
		background: rgba($color: #000000, $alpha: 0.5);
		font-size: 12px;
		z-index: 10000;
		cursor: default;
		hr {
			border: 1px solid #373c44;
			border-bottom: none;
		}
		.panel-menu--item {
			transition: 0.1s background;
			padding: 2px 12px;
			cursor: pointer;
			&:hover {
				background: #c4c4c440;
			}
		}
	}
	.row {
		display: flex;
		flex-direction: row;
	}
	.justify-center {
		justify-content: center;
	}
	.items-center {
		align-items: center;
	}

	.chat-count,
	.user-count {
		position: absolute;
		top: -10px;
		color: white;
		height: 20px;
		min-width: 20px;
		padding: 4px;
		border-radius: 50%;
		font-family: "Inter_SemiBold";
		font-size: 10px;
		line-height: 1;
	}

	.user-count {
		right: -5px;
		background-color: #000;
	}
	.user-count-error {
		background-color: #d03853;
	}
	.chat-count {
		left: -5px;
		background-color: #d03853;
	}

	.chat-count-mobile {
		left: unset;
		right: -5px;
	}

	.ipad-svg-font-size {
		font-size: 10px !important;
	}

	.icon-user-background-blur,
	.icon-user-background-default {
		font-size: 24px;
		vertical-align: text-bottom;
	}

	@media (orientation: landscape) and (max-height: 567px) {
		.call-toolbar-container {
			padding-top: 0;
			padding-bottom: 0;
			z-index: 1000;
		}
		#btn-more-mobile{
			display: none !important;
		}
	}

	@media (orientation: landscape) and (min-height: 568px) and (max-height: 767px) {
		.call-toolbar-container {
			padding-top: 0.5em;
			padding-bottom: 0.5em;
			z-index: 1000;
		}
	}

	@media (min-width: 320px) and (max-width: 767px), (orientation: landscape) and (max-height: 420px) {
		#left-control,
		#right-control,
		.btn-desktop {
			display: none !important;
		}

		.call-toolbar-container {
			padding: 0;
			z-index: 1000;
		}

		.video-controls-container {
			padding: 0;
			width: 100%;
		}

		.video-controls {
			width: 100%;
			justify-content: space-around;
			border-radius: 0 !important;
		}

		.video-controls button {
			margin: 4px 0;
		}

		.btn-mobile {
			display: flex !important;
		}

		.chat-count {
			padding: 4px 4px 4px 3px;
		}
	}
</style>
