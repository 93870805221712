<template>
	<ConfirmationVue
		v-model:show="muteAllAudioConfirm"
		:action-text="meetingHandler?.meetingAudioDisabled ? 'Unmute All' : 'Mute all'"
		body="When the host or moderator mutes, participants will be muted.
					<br><br>
					When the host or moderator unmutes, participants will have the ability to unmute themselves."
		@execute="meetingHandler.muteAllAudio()" />
	<ConfirmationVue
		v-model:show="muteAllVideoConfirm"
		:action-text="meetingHandler?.meetingVideoDisabled ? 'Turn on video' : 'Turn off video'"
		body="When the host or moderator turns off video, participants' videos will be turned off.
					<br><br>
					When the host or moderator turns the video on, participants will have the ability to turn on their videos themselves."
		@execute="meetingHandler.muteAllVideo()" />

	<transition name="fade">
		<div class="info-box position-fixed" role="alert" aria-live="assertive" aria-atomic="true" v-show="modalOpen">
			<div class="text-white d-inline-block rounded-3 bg-dark show">
				<div class="d-flex">
					<div class="toast-body" v-html="message"></div>
					<button
						type="button"
						class="btn-close btn-close-white me-2 m-auto"
						data-bs-dismiss="toast"
						aria-label="Close"
						@click="closeToast"></button>
				</div>
			</div>
		</div>
	</transition>

	<transition>
		<div
			id="modal-sms-invite"
			class="custom-modal modal show sms-modal"
			:class="{ fade: isMobileBrowser }"
			tabindex="-1"
			aria-hidden="true"
			v-show="smsModalOpen"
			@click="tryCloseSMS">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Send Text Invite</h5>
						<i class="fal fa-times close" @click="closeSmsModal"></i>
					</div>
					<div class="modal-body">
						<div class="flex-column">
							<div class="form-group">
								<label class="form-label" for="name">Phone Number</label>
								<vue-tel-input
									ref="phoneInput"
									:border-radius="0"
									:validCharactersOnly="true"
									valid-color="#000000"
									@validate="validPhone"
									@keyup.enter="sendSMS"
									v-model="phoneNumber" />
							</div>
							<div class="form-group">
								<button
									class="btn-primary ls-btn"
									v-bind:disabled="!isPhoneNumberValid"
									@click="sendSMS">
									Send Invite
								</button>
							</div>
							<div class="form-group">
								<button class="btn-light ls-btn" @click="closeSmsModal">Cancel</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>

	<transition name="fade">
		<div
			id="modal-more"
			tabindex="-1"
			role="dialog"
			class="modal show fade"
			aria-modal="true"
			v-show="showMoreModal">
			<div role="document" class="modal-dialog">
				<div class="modal-content">
					<div class="modal-body">
						<div class="position-relative">
							<h6>Call Options</h6>
							<div class="more-options">
								
								<div
									data-dismiss="modal"
									class="option"
									:class="{ 'width-50': optionsHalfWidth }"
									v-if="meetingHandler?.canChangeCamera"
									@click="meetingHandler?.flipCamera">
									<div class="d-flex icon"><i class="fal fa-sync"></i></div>
									<div class="d-flex text">Flip Camera</div>
								</div>
								<div @click="raiseHand" class="option" :class="{ 'width-50': optionsHalfWidth }">
									<div class="d-flex icon" :class="{ active: meetingHandler?.handRaised }">
										<i class="fa fa-hand-paper"></i>
									</div>
									<div class="d-flex text">
										{{ meetingHandler?.handRaised ? "Lower Hand" : "Raise Hand" }}
									</div>
								</div>
								<div
									@click="meetingHandler?.toggleRecording()"
									class="option record-container"
									:class="{
										disabled: meetingHandler?.isFreeAccount,
										'width-50': optionsHalfWidth,
									}"
									v-show="meetingHandler?.isHost">
									<div class="d-flex icon">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="27"
											height="11"
											viewBox="0 0 27 11"
											fill="none">
											<path
												d="M3.24301 0.149864C4.76057 0.149864 5.87795 0.414623 6.59515 0.944142C7.32275 1.47366 7.68655 2.27793 7.68655 3.35695C7.68655 3.8465 7.5878 4.27611 7.39031 4.64578C7.20322 5.00545 6.94856 5.31517 6.62634 5.57493C6.31451 5.82471 5.97669 6.02952 5.6129 6.18937L8.88708 10.8501H6.26773L3.6172 6.74387H2.3543V10.8501H0V0.149864H3.24301ZM3.0715 2.00817H2.3543V4.90055H3.11828C3.89784 4.90055 4.45394 4.77566 4.78655 4.52589C5.12956 4.27611 5.30107 3.90645 5.30107 3.41689C5.30107 2.90736 5.11917 2.54768 4.75537 2.33787C4.40197 2.11807 3.84068 2.00817 3.0715 2.00817Z"
												fill="currentColor" />
											<path
												d="M16.7008 10.8501H10.2927V0.149864H16.7008V2.00817H12.647V4.36104H16.4202V6.21935H12.647V8.97684H16.7008V10.8501Z"
												fill="currentColor" />
											<path
												d="M23.7414 1.88828C22.8371 1.88828 22.1459 2.21299 21.6677 2.8624C21.1896 3.51181 20.9505 4.401 20.9505 5.52997C20.9505 6.66894 21.1688 7.55313 21.6054 8.18256C22.0523 8.802 22.7643 9.11172 23.7414 9.11172C24.1987 9.11172 24.6509 9.06176 25.0978 8.96185C25.5552 8.86195 26.0489 8.72207 26.579 8.54223V10.4455C26.0905 10.6353 25.6072 10.7752 25.129 10.8651C24.6509 10.955 24.1156 11 23.5231 11C22.3798 11 21.4339 10.7752 20.6855 10.3256C19.9475 9.86603 19.4018 9.22661 19.0484 8.40736C18.695 7.57811 18.5183 6.61399 18.5183 5.51499C18.5183 4.43597 18.721 3.48184 19.1264 2.65259C19.5317 1.82334 20.119 1.17393 20.8882 0.70436C21.6677 0.234786 22.6188 0 23.7414 0C24.2923 0 24.8432 0.0699364 25.3941 0.209809C25.9554 0.339691 26.4907 0.519528 27 0.749319L26.236 2.59264C25.8203 2.40282 25.3993 2.23797 24.9731 2.09809C24.5573 1.95822 24.1468 1.88828 23.7414 1.88828Z"
												fill="currentColor" />
										</svg>
									</div>
									<div class="d-flex text">
										{{ meetingHandler?.isFreeAccount ? "Premium Feature" : "Record" }}
									</div>
								</div>
								<div
									@click="openSettings()"
									data-dismiss="modal"
									class="option"
									:class="{ 'width-50': optionsHalfWidth }">
									<div class="d-flex icon"><i class="fas fa-cog"></i></div>
									<div class="d-flex text" style="text-align: center;">Switch Audio or <br/>Video Source</div>
								</div>
								<div
									@click="reportIssue"
									data-dismiss="modal"
									class="option"
									:class="{ 'width-50': optionsHalfWidth }">
									<div class="d-flex icon">
										<img src="../../assets/img/warning.svg" alt="Report Issue" />
									</div>
									<div class="d-flex text">Report Issue</div>
								</div>
							</div>

							<br />
							<h6>Invite</h6>
							<div class="more-options">
								<div class="option" @click="sendEmail" >
									<div class="d-flex icon"><i class="fas fa-envelope"></i></div>
									<div class="d-flex text">Send Email</div>
								</div>
								<div class="option" @click="toggleSmsModal" >
									<div class="d-flex icon"><i class="fas fa-comment-dots"></i></div>
									<div class="d-flex text">Send Text</div>
								</div>
								<div class="option" @click="copyMeetingLink" >
									<div class="d-flex icon"><i class="fas fa-copy"></i></div>
									<div class="d-flex text">Copy Invite Link</div>
								</div>
							</div>

							<div v-if="meetingHandler?.botEnabled && meetingHandler?.canStartBot">
								<br />
								<h6>Artificial Intelligence</h6>
								<div class="more-options">
									<div class="option" 
										:disabled="meetingHandler?.botConnecting && !meetingHandler?.botConnected"
										@click="inviteLiveSwitchBot">
										<div class="d-flex icon bot" style="padding:0;overflow:hidden;background-color:#fff;" :class="{
											'joined': meetingHandler?.botConnected,
											'joining': meetingHandler?.botConnecting
										}">
											
											<img 
												style="background-color: #eee;width:36px;border-radius:30px;padding-top:26px;" 
												src="@/assets/img/larry-the-lab.png" 
												:alt="meetingHandler.botConnected ? 'Larry is active and working!' : 'Click to invite Larry to the call'" 
												/>
											
										</div>
										<div class="d-flex text">{{ meetingHandler.botConnected ? 'Stop Larry':'Invite Larry' }}</div>
										<!--<button 
					type="button" id="btn-invite-bot"
					style="overflow:hidden;background-color:#eee"
					:class="{
					'joined': meetingHandler.botConnected,
					'joining': meetingHandler.botConnecting
				}"
					class="btn bot"
					:disabled="meetingHandler.botConnecting && !meetingHandler.botConnected"
					@click="inviteLiveSwitchBot">
				<img style="background-color: #eee;width:38px !important;border-radius:30px;" src="../assets/img/larry-the-lab.png" :alt="meetingHandler.botConnected ? 'Larry is active and working!' : 'Click to invite Larry to the call'" />
				
			</button>-->
									</div>
									
								</div>
							</div>
						</div>
						<button type="button" class="btn btn-primary btn-cancel-share" @click="closeMoreDialog">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>

	<transition name="fade">
		<div
			id="modal-invite"
			tabindex="-1"
			role="dialog"
			class="modal show fade"
			aria-modal="true"
			v-show="showShareModal">
			<div role="document" class="modal-dialog">
				<div class="modal-content">
					<div class="modal-body">
						<h4 class="modal-title ff-orpheuspro text-center">Share Invite</h4>
						<div class="position-relative">
							<div class="invite-options">
								<div class="option" @click="sendEmail">
									<div class="d-flex text">Send Email</div>
									<div class="d-flex icon"><i class="fas fa-envelope"></i></div>
								</div>
								<hr class="hr" />
								<div class="option" @click="toggleSmsModal">
									<div class="d-flex text">Send Text</div>
									<div class="d-flex icon"><i class="fas fa-comment-dots"></i></div>
								</div>
								<hr class="hr" />
								<div class="option" @click="copyMeetingLink">
									<div class="d-flex text">Copy Invite Link</div>
									<div class="d-flex icon"><i class="fas fa-copy"></i></div>
								</div>
							</div>
						</div>
						<button
							type="button"
							data-dismiss="modal"
							class="btn btn-primary btn-cancel-share"
							@click="closeShareDialog">
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script lang="ts" setup>
	import type MeetingHandler from "@/classes/MeetingHandler";
	import ConfirmationVue from "./Confirmation.vue";
	import { inject, ref } from "vue";
	import useEventBus from "@/composables/useEventBus";
	import type { ChannelDetails } from "@/classes/ChannelService";
	import useLocalStorage from "@/composables/useLocalStorage";
	import type RoomSipNumber from "@liveswitch/sdk/dist/api/models/RoomSipNumber";
	import useHelpers from "@/composables/useHelpers";
	import { computed } from "@vue/reactivity";
	import { type IMeetingInviteService, MeetingInviteService } from "@/classes/MeetingInviteService";
	import { SeverityLevel, type ApplicationInsights } from "@microsoft/applicationinsights-web";
	import { InjectionKeyAppInsights } from "@/composables/injectKeys";

	import { blurEnabled } from "@/composables/useLocalStorage";

	

	const appInsights = inject(InjectionKeyAppInsights) as ApplicationInsights;

	const smsTemplate =
		"Hi, [USERNAME] has invited you to join meeting '[MEETING_SUBJECT]' using LiveSwitch Video, the new way to meet. Join the meeting now in your favorite browser on desktop or mobile: [MEETING_URL]. You can also dial in with the following number: [DIAL_IN],[PASSCODE]";

	function useToast() {
		const message = ref("");

		const modalOpen = ref(false);
		let oncloseCallback: (() => void) | undefined;

		eventBus.onEvent("toast-open", (d: { text: string; timeout?: number; onclose?: () => void }) => {
			message.value = d.text;
			modalOpen.value = true;
			oncloseCallback = d.onclose;
			if (d.timeout) {
				setTimeout(closeToast, d.timeout);
			}
		});

		eventBus.onEvent("toast-close", () => {
			modalOpen.value = false;
		});

		return {
			closeToast() {
				modalOpen.value = false;
				if (oncloseCallback) {
					oncloseCallback();
				}
			},
			message,
			modalOpen,
		};
	}

	async function inviteLiveSwitchBot() {
		useEventBus().emitEvent("bot-open");
		closeMoreDialog()
	}

	const eventBus = useEventBus();
	const muteAllAudioConfirm = ref(false);
	const muteAllVideoConfirm = ref(false);
	const showMoreModal = ref(false);
	const showShareModal = ref(false);
	const phoneNumber = ref("");
	const email = ref("TODO Email");
	const title = ref("TODO Title");
	const dialinId = ref("TODO Dialin");
	const meetingHandler = ref<MeetingHandler>();
	const smsModalOpen = ref(false);
	const isPhoneNumberValid = ref(false);

	eventBus.onEvent("mute-all-video", () => {
		muteAllVideoConfirm.value = true;
	});

	eventBus.onEvent("mute-all-audio", () => {
		muteAllAudioConfirm.value = true;
	});

	eventBus.onEvent("more-dialog", () => {
		showMoreModal.value = true;
	});

	eventBus.onEvent("close-more-dialog", () => {
		showMoreModal.value = false;
	});

	eventBus.onEvent("toggle-share-modal", () => {
		showShareModal.value = true;
	});

	eventBus.onEvent("close-share-modal", () => {
		showShareModal.value = false;
	});

	eventBus.onEvent("joined", (handler: MeetingHandler) => {
		meetingHandler.value = handler;
	});

	const optionsHalfWidth = computed(() => {
		let options = 3;

		if (meetingHandler.value?.isHost) {
			options += 1;
		}

		if (meetingHandler.value?.canChangeCamera) {
			options += 1;
		}

		return options % 2 === 0;
	});

	const { closeToast, message, modalOpen } = useToast();

	function closeMoreDialog() {
		eventBus.emitEvent("close-more-dialog");
	}

	function closeShareDialog() {
		eventBus.emitEvent("close-share-modal");
	}

	function openSettings() {
		eventBus.emitEvent("close-more-dialog");
		eventBus.emitEvent("settings-open");
	}

	function raiseHand() {
		closeMoreDialog();
		meetingHandler.value?.raiseHand();
	}

	function copyMeetingLink() {
		closeShareDialog();
		meetingHandler.value?.copyMeetingLink();
		useEventBus().navAlert("Meeting link copied to your clipboard");
	}

	function isMobileBrowser() {
		return useHelpers().isMobileBrowser;
	}

	async function toggleSmsModal() {
		closeShareDialog();
		closeMoreDialog();
		smsModalOpen.value = true;
	}

	const virtualBackgroundEnabled = computed(() =>{
		if(!meetingHandler || !meetingHandler.value){
			//console.warn('No meeting handler')
			return false
		}
		console.log(meetingHandler.value.backgroundMode + ' when checking vb enabled')
		return meetingHandler.value.backgroundMode == 'virtual'
	})

	const blurBackgroundEnabled = computed(() =>{
		if(!meetingHandler || !meetingHandler.value){
			//console.warn('No meeting handler')
			return false
		}
		return meetingHandler.value.backgroundMode == 'blur'
	})

	async function toggleBackgroundMode(mode : 'blur' | 'virtual' | '') {
		if(!meetingHandler || !meetingHandler.value){
			console.warn('No meeting handler')
			return
		}
		const mh = meetingHandler.value
		console.log('toggling ' + mode + ', current status: ' + mh.backgroundMode)
		
		if(mode == 'blur'){
			// if we are already blurring, toggle off
			if(mh.backgroundMode == 'blur'){
				mh.backgroundMode = ''
			}else{
				// otherwise turn it on
				mh.backgroundMode = 'blur'
			}
		}else if(mode == 'virtual'){
			// if we are already virtual, toggle off
			if(mh.backgroundMode == 'virtual'){
				mh.backgroundMode = ''
			}else{
				// otherwise turn it on
				mh.backgroundMode = 'virtual'
			}
		}
	}

	useEventBus().onEvent("toggle-invite-sms", (newValue: boolean | undefined) => {
		smsModalOpen.value = !smsModalOpen.value;
	});

	function closeSmsModal() {
		// smsModalOpen.value = false;
		phoneNumber.value = "";
		useEventBus().emitEvent("toggle-invite-sms");
	}

	function tryCloseSMS(e: MouseEvent) {
		if ((e.target as HTMLDivElement)?.classList.contains("sms-modal")) {
			closeSmsModal();
		}
	}

	function validPhone(args) {
		isPhoneNumberValid.value = args.valid;
	}

	async function sendSMS() {
		if (!isPhoneNumberValid.value) return;
		closeShareDialog();
		const url = window.location.toString().split("#")[0] + "#";
		const username = useLocalStorage().getUsername();
		const channel: ChannelDetails = useLocalStorage().getChannel();
		const subject = channel.MeetSubject && channel.MeetSubject != "" ? channel.MeetSubject : channel.ChannelName;
		const numbers: RoomSipNumber[] = useLocalStorage().getDialInNumbers(meetingHandler.value?.channelKey);
		let dialInNumber = "";
		let passcode = "";

		if (numbers && numbers.length > 0) {
			dialInNumber = numbers[0].phoneNumber;
			passcode = `${numbers[0].numberPasscode}#`;
		}

		const smsBody = smsTemplate
			.replace("[USERNAME]", username)
			.replace("[MEETING_SUBJECT]", subject)
			.replace("[MEETING_URL]", url)
			.replace("[DIAL_IN]", dialInNumber)
			.replace("[PASSCODE]", passcode);

		

		if (!phoneNumber.value) {
			return;
		}

		let number = phoneNumber.value
			.replaceAll("-", "")
			.replaceAll(" ", "")
			.replaceAll(".", "")
			.replaceAll("(", "")
			.replaceAll(")", "");

		if (!number.startsWith("+")) {
			number = `+1${number}`;
		}

		if (!useHelpers().isValidPhoneNumber(number)) {
			useEventBus().navAlert("Failed to send invite. Invalid phone number");
			return;
		}

		console.log('sending', smsBody)

		useEventBus().loading("Sending invite");
		const inviteService: IMeetingInviteService = new MeetingInviteService();
		const response = await inviteService.sendSmsAsync({
			Message: smsBody,
			PhoneNumber: number,
		});

		useEventBus().loadingComplete();

		if (!response.error) {
			useEventBus().navAlert("Invite sent successfully");
		} else {
			const errorMessage = "An error occurred while sending the meeting invite";

			appInsights.trackException(
				{
					exception: response.exception,
					severityLevel: SeverityLevel.Critical,
				},
				useHelpers().getLoggingProperties("SMSInviteError", errorMessage)
			);

			useEventBus().navAlert(errorMessage);
		}
		closeSmsModal();
	}

	function sendEmail() {
		closeShareDialog();
		closeMoreDialog();
		useEventBus().emitEvent("toggle-invite", undefined);
	}

	function reportIssue() {
		closeMoreDialog();
		useEventBus().emitEvent("toggle-report-issue");
	}
</script>

<style>
	.info-box {
		z-index: 10000;
		text-align: center;
		font-size: 14px;
		width: 100%;
		margin-top: 4px;
	}

	.ls-btn {
		width: 100%;
		height: 48px;
		margin-top: 16px;
		border-radius: 10px;
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		font-family: "Inter_Regular";
		font-weight: bold;
		text-align: center;
		color: #ffffff;
		cursor: pointer;
		background-color: #346ee0;
	}

	#modal-sms-invite {
		z-index: 1001;
	}

	#modal-sms-invite .modal-dialog {
		position: absolute;
		bottom: 40px;
		left: 15px;
	}

	@media (min-width: 320px) and (max-width: 767px), (orientation: landscape) and (max-height: 420px) {
		.info-box {
			margin-top: 65px;
		}

		.icon.highlight{
			background-color: #dfe1ef !important;
			color: #346ee0 !important;
			font-size: 24px;
		}

		.more-options {
			display: flex;
			justify-content: center;
			margin: auto;
			background-color: #1d2029;
			border-radius: 10px;
			flex-wrap: wrap;
		}

		.more-options .option {
			display: flex;
			flex-direction: column;
			flex: 0 1 auto;
			margin: 1rem 0;
			align-items: center;
			cursor: pointer;
			min-width: 33%;
			max-width: 50%;
		}

		.width-50 {
			min-width: 50% !important;
		}

		.more-options .option .icon {
			width: 44px;
			height: 44px;
			align-items: center;
			justify-content: center;
			background-color: #0d0e13;
			padding: 12px;
			border-radius: 50%;
		}

		.more-options .option .text {
			font-size: 0.7rem;
			margin-top: 8px;
		}

		.more-options .active {
			background-color: #dfe1ef !important;
			color: #436dd9;
		}

		.btn-cancel-share {
			background-color: #484e5c !important;
			height: 50px;
			border-radius: 10px;
			width: 100%;
			margin-top: 24px;
			border-color: #484e5c;
		}

		#modal-more .modal-dialog,
		#modal-invite .modal-dialog,
		#modal-sms-invite .modal-dialog {
			width: 100%;
			position: absolute;
			bottom: 0;
			margin: 0;
			left: 0;
		}

		#modal-more .modal-dialog .modal-content,
		#modal-invite .modal-dialog .modal-content {
			background-color: #343b4a;
			color: white;
		}

		.invite-options {
			background-color: #1d2029;
			border-radius: 10px;
			margin-top: 16px;
			color: white;
		}

		#modal-invite .modal-content .modal-title {
			color: white;
		}

		.invite-options .option {
			width: 90%;
			margin: auto;
			display: flex;
			padding: 12px 0;
			align-items: center;
			cursor: pointer;
		}

		.invite-options .option .text {
			flex: 1;
		}

		.invite-options .option .icon {
			justify-self: flex-end;
		}

		.invite-options hr {
			margin: 0;
		}

		.btn-cancel-share {
			background-color: #484e5c;
			height: 50px;
			border-radius: 10px;
			width: 100%;
			margin-top: 24px;
			border-color: #484e5c;
		}
	}

	.record-container.disabled > .icon:first-child {
		background: #4e4e4e;
		color: grey !important;
	}

	.record-container.disabled > .text {
		text-align: center;
	}

	.bot.joined {
		border: 3px solid limegreen;
	}

	@keyframes changewidth {
		from {
			border-width: 0px;
		}

		to {
			border-width: 3px;
		}
	}
	.bot.joining{
		animation-duration: 1s;
		animation-name: changewidth;
		animation-iteration-count: infinite;
		animation-direction: alternate;
		
		border: 3px solid #ffd279;
	}
</style>
