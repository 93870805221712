﻿<template>
	<div class="auth-container" v-show="!hideAuthForm">
		<div class="form-group" v-show="greeting === 'guestFeedback'">
			<div class="greeting">We're glad you enjoyed using LiveSwitch Video!</div>
			<div class="greeting">Create a free account and start hosting meetings today.</div>
		</div>
		<div class="form-group" v-show="!compact || !showSsoFields">
			<label class="form-label" for="firstName">First Name</label>
			<input
				type="text"
				id="firstName"
				class="form-control"
				v-model="firstName"
				maxlength="25"
				@keyup.enter="signUp" />
		</div>
		<div class="form-group" v-show="!compact || !showSsoFields">
			<label class="form-label" for="lastName">Last Name</label>
			<input
				type="text"
				id="lastName"
				class="form-control"
				v-model="lastName"
				maxlength="25"
				@keyup.enter="signUp" />
		</div>
		<div class="form-group" v-show="!compact || !showSsoFields">
			<label class="form-label" for="companyName">Company Name</label>
			<input
				type="text"
				id="companyName"
				placeholder="Optional"
				class="form-control"
				v-model="companyName"
				maxlength="128"
				@keyup.enter="signUp" />
		</div>
		<div class="form-group" v-show="!compact || !showSsoFields">
			<label class="form-label" for="email">Email</label>
			<input type="text" id="email" class="form-control" v-model="email" maxlength="50" @keyup.enter="signUp" />
		</div>
		<div class="form-group" v-show="!compact || !showSsoFields">
			<label class="form-label" for="password">Password</label>
			<input
				type="password"
				id="password"
				class="form-control"
				v-model="password"
				maxlength="25"
				@keyup.enter="signUp" />
		</div>
		<div class="form-group" v-show="!compact || !showSsoFields">
			<label class="form-label" for="confirmPassword">Confirm Password</label>
			<input
				type="password"
				id="confirmPassword"
				class="form-control"
				v-model="confirmPassword"
				maxlength="25"
				@keyup.enter="signUp" />
		</div>
		<!--		<vue-recaptcha-->
		<!--			class="ls-captcha"-->
		<!--			ref="recaptcha"-->
		<!--			:sitekey="AuthenticationService.getCaptchaKey()"-->
		<!--			@verify="verifyCaptcha"-->
		<!--			@expire="expireCaptcha" />-->
		<div class="form-group">
			<span class="terms">
				By signing up, You agree to our
				<a href="https://www.liveswitch.com/legal-aup" target="_blank">Terms of Use</a>
				and
				<a href="https://www.liveswitch.com/legal-privacy-policy" target="_blank">Privacy Policy</a>.
			</span>
		</div>
		<div class="form-group" v-show="!compact || !showSsoFields">
			<button class="btn-primary ls-btn" @click="signUp">Sign Up</button>
		</div>
		<div class="or-separator" v-show="!compact || !showSsoFields">OR</div>
		<div class="form-group" v-show="compact && !showSsoFields">
			<button class="btn-primary ls-btn btn-other" @click="toggleSsoFields">
				Sign up with Google or LinkedIn
			</button>
		</div>
		<div class="form-group" v-show="!compact || showSsoFields">
			<form :action="ssoAction" method="post" ref="ssoForm" id="SSO" :target="inMeeting ? 'SSO' : ''">
				<div class="form-group">
					<button type="button" class="ls-btn btn-google" @click="signUpSSO(ACCOUNT_TYPE_GOOGLE)">
						<img src="../assets/img/btn_google_dark_normal_ios.svg" height="26" width="26" />
						Join with Google
					</button>
				</div>
				<!-- <div class="form-group">
					<button type="button" class="ls-btn btn-facebook" @click="signUpSSO(ACCOUNT_TYPE_FACEBOOK)">
						<i class="fab fa-facebook-f"></i>
						Join with Facebook
					</button>
				</div> -->
				<div class="form-group">
					<button type="button" class="ls-btn btn-linked-in" @click="signUpSSO(ACCOUNT_TYPE_LINKEDIN)">
						<i class="fab fa-linkedin-in"></i>
						Join with LinkedIn
					</button>
				</div>
				<input name="AccountType" type="hidden" :value="ssoAccountType" />
				<input name="SharedFingerprint" type="hidden" :value="serializedFingerprint" />
				<input name="ReturnUrl" type="hidden" :value="returnUrl" />
				<input name="InviteToken" type="hidden" :value="inviteToken" />
				<input name="Timezone" type="hidden" :value="timezone" />
			</form>
		</div>

		<div class="or-separator" v-show="compact && showSsoFields && greeting !== 'guestFeedback'">OR</div>
		<div class="form-group" v-show="compact && showSsoFields">
			<button class="btn-primary ls-btn btn-other" @click="toggleSsoFields">
				{{ greeting === "guestFeedback" ? "Join " : "Sign up" }} with email
			</button>
		</div>

		<div class="or-separator" v-show="compact && showSsoFields && greeting === 'guestFeedback'">OR</div>
		<div class="form-group" v-show="greeting === 'guestFeedback'">
			<button class="btn-primary ls-btn" @click="goHome">Back to homepage</button>
		</div>
	</div>
	<div class="auth-container payment-container" v-if="premiumFlow" v-show="showPaymentForm">
		<iframe
			v-if="showPaymentForm"
			:src="paymentUrl"
			ref="iFrame"
			@load="resizeIframe()"
			frameborder="0"
			scrolling="no"></iframe>
	</div>
</template>

<script lang="ts" setup>
	import { type IAuthenticationService, SignUpRequest } from "@/classes/AuthenticationService";
	import useEventBus from "@/composables/useEventBus";
	import { ref } from "@vue/reactivity";
	import { nextTick, inject, onMounted, onUnmounted } from "vue";
	import Swal from "sweetalert2/dist/sweetalert2.js";
	import { getCookie } from "typescript-cookie";
	import { useGtm } from "@gtm-support/vue-gtm";
	import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
	import { authenticationServiceKey, InjectionKeyAppInsights } from "@/composables/injectKeys";
	import useHelpers from "@/composables/useHelpers";
	import { useCookies } from "vue3-cookies";

	const appInsights = inject(InjectionKeyAppInsights) as ApplicationInsights;
	const authenticationService = inject(authenticationServiceKey) as IAuthenticationService;
	authenticationService.appInsights = appInsights;
	const ACCOUNT_TYPE_GOOGLE = "USER_GOOGLE";
	const ACCOUNT_TYPE_FACEBOOK = "USER_FACEBOOK";
	const ACCOUNT_TYPE_LINKEDIN = "USER_LINKEDIN";
	const LOGIN_METHOD_GOOGLE = "Google";
	const LOGIN_METHOD_FACEBOOK = "Facebook";
	const LOGIN_METHOD_LINKEDIN = "LinkedIn";
	const LOGIN_METHOD_USERNAME = "Username";
	const firstName = ref("");
	const lastName = ref("");
	const companyName = ref("");
	const email = ref("");
	const password = ref("");
	const confirmPassword = ref("");
	const userFingerprint = ref<{ WebFingerprint: any }>();
	const serializedFingerprint = ref("");
	const recaptcha = ref(null);
	const captcha = ref("");
	const inviteToken = ref("");
	const referralCode = ref("");
	const timezone = ref("");
	const premiumFlow = ref(false);
	const showPaymentForm = ref(false);
	const hideAuthForm = ref(false);
	const paymentUrl = import.meta.env.VITE_PAYMENT_URL;
	const iFrame = ref<HTMLIFrameElement>();
	const view = ref("");
	const greeting = ref("");
	const compact = ref(false);
	const showSsoFields = ref(false);
	const redirect = ref("");
	const ssoAccountType = ref("");
	const ssoForm = ref<HTMLFormElement>();
	const ssoInterval = ref(0);

	function resizeIframe() {
		iFrame.value?.contentWindow.postMessage("FrameHeight", "*");
	}

	onMounted(async () => {
		try {
			await authenticationService.clearDeadCookies();

			const params = new URLSearchParams(window.location.search.split("#")[0]);
			const plan = params.get("plan");

			if (plan && plan === "premium") {
				premiumFlow.value = true;

				window.addEventListener("message", function (event) {
					if (event.data.hasOwnProperty("FrameHeight")) {
						if (iFrame.value) {
							hideAuthForm.value = true;
							iFrame.value.style.height = `${event.data.FrameHeight}px`;
							useEventBus().emitEvent("loading-complete");
						}
					} else if (event.data.type === "Payment") {
						if (event.data.success == true) {
							Swal.fire({
								title: "Congratulations!",
								html: "Your subscription has been purchased!",
								icon: "success",
								confirmButtonText: "Home",
							}).then(() => {
								window.location.href = import.meta.env.VITE_USER_SITE_URL;
							});
						} else {
							let message = "It looks there was an issue with your subscription.";

							if (event.data.message) {
								message = `${message}<br>${event.data.message}`;
							}

							Swal.fire({
								title: "Uh oh!",
								html: message,
								confirmButtonText: "Close",
							}).then(() => {
								if (!event.data.retry) {
									window.location.href = import.meta.env.VITE_USER_SITE_URL;
								}
							});
						}
					}
				});
			}

			if (props.action) {
				localStorage.setItem("auth-action", props.action);
			}

			let userSiteUrl = import.meta.env.VITE_USER_SITE_URL;
			let clientSiteUrl = import.meta.env.VITE_CLIENT_SITE_URL;
			let adminSiteUrl = import.meta.env.VITE_ADMIN_SITE_URL;

			let referrer = document.referrer;

			if (
				referrer !== window.location.origin &&
				referrer !== window.location.origin + "/" &&
				referrer !== window.location.href &&
				(referrer?.startsWith(userSiteUrl) ||
					referrer?.startsWith(clientSiteUrl) ||
					referrer?.startsWith(adminSiteUrl))
			) {
				localStorage.setItem("auth-referrer", referrer);
			}

			const webFingerprint = await authenticationService.getFingerprint();
			userFingerprint.value = { WebFingerprint: webFingerprint };
			userFingerprint.value.WebFingerprint.components.canvas = {};
			userFingerprint.value.WebFingerprint.components.math = {};
			userFingerprint.value.WebFingerprint.components.plugins = {};
			serializedFingerprint.value = JSON.stringify(userFingerprint.value);

			if (window.location.search.indexOf("ssoComplete=true") !== -1) {
				useEventBus().emitEvent("loading", "Registering...");

				try {
					await authenticationService.clearDeadCookies();
					let ssoResponse = authenticationService.signInSSO();
					let response = await authenticationService.signIn(undefined, ssoResponse);

					if (response.isValid()) {
						onAuthenticated(getLoginMethod());
					} else {
						Swal.fire({
							title: "Error",
							html: response.Message?.Reason ?? "An unexpected error occurred during single sign-in registration. Please try again.",
							confirmButtonText: "Close",
						});
					}
				} catch (err: any) {
					Swal.fire({
						title: "Error",
						html: "An unexpected error occurred. Please try again.",
						confirmButtonText: "Close",
					});

					appInsights.trackException(
						{
							exception: err,
							id: "SSOSignInFailed",
							severityLevel: SeverityLevel.Critical,
						},
						{
							Name: "SSOSignInFailed",
							Email: email.value,
							Message: "Error signing in after SSO registration",
						}
					);
				} finally {
					if (!premiumFlow.value) {
						useEventBus().emitEvent("loading-complete");
					}
				}
			} else if (window.location.search.indexOf("ssoError=true") !== -1) {
				let message;
				const responseBase64 = getCookie("X-Sign-In-Response");

				if (responseBase64) {
					const responseJson = atob(responseBase64);
					message = JSON.parse(responseJson)?.Message?.Reason;
				}

				message = message ?? "An unexpected error occurred with no message during sigle sign-in. Please try again.";

				Swal.fire({
					title: "Error",
					html: message,
					confirmButtonText: "Close",
				});
			}

			if (!props.action) {
				useHelpers().removeQueryParams(["ssoComplete", "ssoError"]);
			}
		} catch (error: any) {
			appInsights.trackException(
				{
					exception: error,
					id: "SSORegistrationFailed",
					severityLevel: SeverityLevel.Critical,
				},
				useHelpers().getLoggingProperties("SSORegistrationFailed", "SSORegistrationFailed", {
					referrer: document.referrer,
				})
			);
		}
	});

	const props = defineProps({
		action: String,
		inMeeting: Boolean,
	});

	const emits = defineEmits(["onAuthenticated"]);

	const ssoAction = authenticationService.getApiEndpoint() + "api/Account/SSOChallenge";
	let returnUrl = window.location.href.split("#")[0];
	let index;

	if ((index = returnUrl.indexOf("ssoComplete=true")) !== -1) {
		returnUrl = returnUrl.substring(0, index - 1);
	}

	if ((index = returnUrl.indexOf("ssoError=true")) !== -1) {
		returnUrl = returnUrl.substring(0, index - 1);
	}

	returnUrl += "#";

	if (props.inMeeting) {
		returnUrl = window.location.origin + "/meetingsso";
	}

	const params = new URLSearchParams(window.location.search.split("#")[0]);
	inviteToken.value = params.get("inviteToken");
	referralCode.value = params.get("referralCode");
	timezone.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
	view.value = params.get("view");
	if (view.value) {
		switch (view.value) {
			case "guestFeedback":
				greeting.value = "guestFeedback";
				compact.value = true;
				showSsoFields.value = true;
				redirect.value = "user";
				break;
			default:
				console.warn(`Invalid view ${view.value}.`);
		}
	} else {
		greeting.value = params.get("greeting");
		compact.value = params.get("compact") === "true";
		showSsoFields.value = compact.value;
		redirect.value = params.get("redirect") || "";
	}

	async function signUpSSO(accountType: string) {
		ssoAccountType.value = accountType;
		await nextTick();
		if (props.inMeeting) {
			window.open("", "SSO");
		}
		ssoForm.value?.submit();

		if (props.inMeeting) {
			ssoInterval.value = window.setInterval(() => {
				if (
					useCookies().cookies.isKey("X-Sign-In-Response") &&
					useCookies().cookies.isKey("Authorization") &&
					useCookies().cookies.isKey("RefreshToken")
				) {
					let loginMethod = LOGIN_METHOD_GOOGLE;
					if (ssoAccountType.value == ACCOUNT_TYPE_FACEBOOK) {
						loginMethod = LOGIN_METHOD_FACEBOOK;
					} else if (ssoAccountType.value == ACCOUNT_TYPE_LINKEDIN) {
						loginMethod = LOGIN_METHOD_LINKEDIN;
					}
					const responseBase64 = getCookie("X-Sign-In-Response");
					if (responseBase64) {
						const responseJson = atob(responseBase64);
						const jsonObject = JSON.parse(responseJson);
						if (jsonObject?.DisplayName) {
							localStorage.setItem("Username", jsonObject?.DisplayName);
						}
						if (jsonObject?.UserAccountId) {
							localStorage.setItem("UserAccountId", jsonObject?.UserAccountId);
						}
						if (jsonObject?.SelectedTenantId) {
							localStorage.setItem("TenantId", jsonObject?.SelectedTenantId);
						}
					}
					onAuthenticated(loginMethod);
					clearSSOInterval();
				}
			}, 1000);
		}
	}

	function clearSSOInterval() {
		if (ssoInterval.value != 0) {
			window.clearInterval(ssoInterval.value);
			ssoInterval.value = 0;
		}
	}

	onUnmounted(() => {
		clearSSOInterval();
	});

	function goHome() {
		window.location.href = import.meta.env.VITE_USER_SITE_URL;
	}

	async function signUp() {
		if (password.value.length === 0) {
			Swal.fire({
				title: "Error",
				text: "Password cannot be empty.",
				confirmButtonText: "Close",
			});
			return;
		}

		if (confirmPassword.value.length === 0) {
			Swal.fire({
				title: "Error",
				text: "Confirm Password cannot be empty.",
				confirmButtonText: "Close",
			});
			return;
		}

		if (password.value !== confirmPassword.value) {
			Swal.fire({
				title: "Error",
				text: "Password and Confirm Password do not match.",
				confirmButtonText: "Close",
			});
			return;
		}

		if (!captcha.value && !authenticationService.isAutomatedTest()) {
			Swal.fire({
				title: "Error",
				text: "You must complete the reCAPTCHA before signing up.",
				confirmButtonText: "Close",
			});
			return;
		}

		useEventBus().emitEvent("loading", "Registering...");
		console.log(`FirstName: ${firstName.value}, LastName: ${lastName.value}, Email: ${email.value}`);

		const request = new SignUpRequest(
			"USER",
			`${firstName.value} ${lastName.value}`,
			companyName.value,
			email.value,
			password.value,
			window.navigator.platform,
			userFingerprint.value,
			timezone.value,
			captcha.value,
			undefined,
			inviteToken.value,
			referralCode.value
		);

		request.showErrorMessagePopUp = false;
		let response = await authenticationService.signUp(request);

		if (response.isValid()) {
			onAuthenticated(LOGIN_METHOD_USERNAME);
		} else {
			useEventBus().emitEvent("loading-complete");
			recaptcha.value?.reset();
			Swal.fire({
				title: "Error",
				text: response.Message?.Reason ?? "An unexpected error occurred while signing up.",
				confirmButtonText: "Close",
			});
		}
	}

	async function verifyCaptcha(response) {
		captcha.value = response;
	}

	async function expireCaptcha() {
		captcha.value = "";
	}

	function getLoginMethod(): string {
		if (document.referrer.toLowerCase().indexOf("google") != -1) {
			return LOGIN_METHOD_GOOGLE;
		} else if (document.referrer.toLowerCase().indexOf("facebook") != -1) {
			return LOGIN_METHOD_FACEBOOK;
		} else if (document.referrer.toLowerCase().indexOf("linkedin") != -1) {
			return LOGIN_METHOD_LINKEDIN;
		}

		return LOGIN_METHOD_USERNAME;
	}

	function onAuthenticated(method: string) {
		let referrer = localStorage.getItem("auth-referrer");
		localStorage.removeItem("auth-referrer");

		let action = localStorage.getItem("auth-action");
		localStorage.removeItem("auth-action");

		useGtm().trackEvent({
			event: "sign_up",
			category: "auth",
			action: "click",
			method: method,
		});

		appInsights.trackMetric({
			name: "sign_up",
			average: 1,
		});

		if (useHelpers().isAuthReferrer(referrer)) {
			referrer = null;
		}

		if (premiumFlow.value) {
			showPaymentForm.value = true;
		} else {
			if (action) {
				emits("onAuthenticated");
			} else if (redirect.value === "user") {
				location.href = import.meta.env.VITE_USER_SITE_URL;
			} else if (
				referrer?.startsWith(window.location.origin) ||
				referrer?.startsWith(import.meta.env.VITE_USER_SITE_URL) ||
				referrer?.startsWith(import.meta.env.VITE_CLIENT_SITE_URL) ||
				referrer?.startsWith(import.meta.env.VITE_ADMIN_SITE_URL)
			) {
				location.href = referrer;
			} else {
				location.href = import.meta.env.VITE_USER_SITE_URL;
			}
		}
	}

	function toggleSsoFields() {
		showSsoFields.value = !showSsoFields.value;
	}
</script>

<style scoped>
	body {
		background-color: #fff !important;
		font-family: "Inter_Regular";
	}

	label {
		flex-grow: 0;
		font-family: "Inter_Regular";
		font-size: 14px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: left;
		color: #4e5d78;
		margin-bottom: 4px;
	}

	input {
		width: 100%;
		height: 45px;
		margin-bottom: 16px;
		flex-grow: 0;
		padding: 12px;
		border-radius: 4px;
		box-shadow: 1px 4px 4px 2px rgb(0 0 0 / 5%);
		border: solid 1px #f8f8f8;
		background-color: #ffffff;
		flex-grow: 0;
		font-family: Inter;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: left;
		color: #323b4b;
		opacity: 1;
		font-family: "Inter_Regular";
	}

	.greeting {
		flex-grow: 0;
		font-family: "Inter_Regular";
		font-size: 14px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: center;
		color: black;
		margin-bottom: 4px;
	}

	.ls-btn {
		width: 100%;
		height: 58px;
		margin-top: 16px;
		border-radius: 10px;
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		font-family: "Inter_Regular";
		font-weight: bold;
		text-align: center;
		color: #ffffff;
		cursor: pointer;
		background-color: #346ee0;
	}

	.payment-container {
		width: 100%;
		min-height: 600px;
	}

	.payment-container iframe {
		width: 100%;
	}

	.btn-google {
		background-color: #4285f4;
		color: #ffffff;
	}

	.btn-facebook {
		background-color: rgba(23, 104, 225, 0.85);
		color: white;
	}

	.btn-linked-in {
		background-color: rgba(8, 101, 160, 0.85);
		color: white;
	}

	.or-separator {
		font-family: "Inter_Regular";
		font-size: 16px;
		font-weight: 600;
		color: #8a94a6;
		margin-top: 10px;
	}

	.ls-captcha {
		display: flex;
		margin-top: 16px;
		padding: 0.375rem 0.75rem;
	}

	.auth-container .form-group {
		width: 100%;
	}

	.terms {
		color: black;
	}
</style>
