﻿<template>
	<div class="title-already-joined" v-show="isAuthenticatedUser && !isRegistrationComplete">
		Congrats! Looks like you’ve already joined <a href="#" @click="openUserSite">LiveSwitch Video</a>
	</div>
	<div class="title-already-joined" v-show="isRegistrationComplete">
		Congrats! You have successfully joined <a href="#" @click="openUserSite">LiveSwitch Video</a>
	</div>
	<div class="auth-container" v-show="!isAuthenticatedUser && !isRegistrationComplete">
		<div class="form-group">
			<span class="title-join">Try LiveSwitch Video for free.</span>
		</div>
		<div class="form-group" v-show="!showSsoFields">
			<label class="form-label" for="firstName">First Name</label>
			<input type="text" id="firstName" class="form-control" v-model="firstName" maxlength="25" />
		</div>
		<div class="form-group" v-show="!showSsoFields">
			<label class="form-label" for="lastName">Last Name</label>
			<input type="text" id="lastName" class="form-control" v-model="lastName" maxlength="25" />
		</div>
		<div class="form-group" v-show="!showSsoFields">
			<label class="form-label" for="companyName">Company Name</label>
			<input
				type="text"
				id="companyName"
				placeholder="Optional"
				class="form-control"
				v-model="companyName"
				maxlength="128" />
		</div>
		<div class="form-group" v-show="!showSsoFields">
			<label class="form-label" for="email">Email</label>
			<input type="text" id="email" class="form-control" v-model="email" maxlength="50" />
		</div>
		<div class="form-group" v-show="!showSsoFields">
			<label class="form-label" for="password">Password</label>
			<input type="password" id="password" class="form-control" v-model="password" maxlength="25" />
		</div>
		<div class="form-group" v-show="!showSsoFields">
			<label class="form-label" for="confirmPassword">Confirm Password</label>
			<input type="password" id="confirmPassword" class="form-control" v-model="confirmPassword" maxlength="25" />
		</div>
		<!--		<vue-recaptcha-->
		<!--			class="ls-captcha"-->
		<!--			ref="recaptcha"-->
		<!--			:sitekey="AuthenticationService.getCaptchaKey()"-->
		<!--			@verify="verifyCaptcha"-->
		<!--			@expire="expireCaptcha" />-->
		<div class="form-group">
			<span class="terms">
				By signing up, You agree to our
				<a href="https://www.liveswitch.com/legal-aup" target="_blank">Terms of Use</a>
				and
				<a href="https://www.liveswitch.com/legal-privacy-policy" target="_blank">Privacy Policy</a>.
			</span>
		</div>
		<div class="form-group" v-show="!showSsoFields">
			<button class="btn-primary ls-btn" @click="signUp">Sign Up</button>
		</div>
		<div class="or-separator" v-show="!showSsoFields">OR</div>
		<div class="form-group" v-show="!showSsoFields">
			<button class="btn-primary ls-btn btn-other" @click="toggleSsoFields">
				Sign up with Google or LinkedIn
			</button>
		</div>
		<div class="form-group" v-show="showSsoFields">
			<form :action="ssoAction" method="post" target="_blank">
				<button type="submit" class="ls-btn btn-google">
					<img src="../assets/img/btn_google_dark_normal_ios.svg" height="26" width="26" />
					Join with Google
				</button>
				<input name="AccountType" type="hidden" value="USER_GOOGLE" />
				<input name="SharedFingerprint" type="hidden" :value="serializedFingerprint" />
				<input name="ReturnUrl" type="hidden" :value="returnUrl" />
				<input name="Timezone" type="hidden" :value="timezone" />
			</form>
		</div>
		<!-- <div class="form-group" v-show="showSsoFields">
			<form :action="ssoAction" method="post" target="_blank">
				<button type="submit" class="ls-btn btn-facebook">
					<i class="fab fa-facebook-f"></i>
					Join with Facebook
				</button>
				<input name="AccountType" type="hidden" value="USER_FACEBOOK" />
				<input name="SharedFingerprint" type="hidden" :value="serializedFingerprint" />
				<input name="ReturnUrl" type="hidden" :value="returnUrl" />
			</form>
		</div> -->
		<div class="form-group" v-show="showSsoFields">
			<form :action="ssoAction" method="post" target="_blank">
				<button type="submit" class="ls-btn btn-linked-in">
					<i class="fab fa-linkedin-in"></i>
					Join with LinkedIn
				</button>
				<input name="AccountType" type="hidden" value="USER_LINKEDIN" />
				<input name="SharedFingerprint" type="hidden" :value="serializedFingerprint" />
				<input name="ReturnUrl" type="hidden" :value="returnUrl" />
			</form>
		</div>
		<div class="or-separator" v-show="showSsoFields">OR</div>
		<div class="form-group" v-show="showSsoFields">
			<button class="btn-primary ls-btn btn-other" @click="toggleSsoFields">Sign up with email</button>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { IAuthenticationService, SignUpRequest } from "@/classes/AuthenticationService";
	import useEventBus from "@/composables/useEventBus";
	import { computed, ref } from "@vue/reactivity";
	import { inject, onMounted } from "vue";
	import Swal from "sweetalert2/dist/sweetalert2.js";
	import { getCookie } from "typescript-cookie";
	import { useGtm } from "@gtm-support/vue-gtm";
	import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
	import { authenticationServiceKey, InjectionKeyAppInsights } from "@/composables/injectKeys";
	import useHelpers from "@/composables/useHelpers";

	const appInsights = inject(InjectionKeyAppInsights) as ApplicationInsights;
	const authenticationService = inject(authenticationServiceKey) as IAuthenticationService;
	authenticationService.appInsights = appInsights;
	const LOGIN_METHOD_GOOGLE = "Google";
	const LOGIN_METHOD_FACEBOOK = "Facebook";
	const LOGIN_METHOD_LINKEDIN = "LinkedIn";
	const LOGIN_METHOD_USERNAME = "Username";
	const firstName = ref("");
	const lastName = ref("");
	const companyName = ref("");
	const email = ref("");
	const password = ref("");
	const confirmPassword = ref("");
	const userFingerprint = ref<{ WebFingerprint: any }>();
	const serializedFingerprint = ref("");
	const recaptcha = ref(null);
	const captcha = ref("");
	const timezone = ref("");

	const showSsoFields = ref(true);
	const isAuthenticatedUser = computed(() => {
		return useHelpers().isAuthenticatedUser();
	});
	const isRegistrationComplete = ref(false);

	onMounted(async () => {
		const webFingerprint = await authenticationService.getFingerprint();
		userFingerprint.value = { WebFingerprint: webFingerprint };
		userFingerprint.value.WebFingerprint.components.canvas = {};
		userFingerprint.value.WebFingerprint.components.math = {};
		userFingerprint.value.WebFingerprint.components.plugins = {};
		serializedFingerprint.value = JSON.stringify(userFingerprint.value);

		if (window.location.search.indexOf("ssoComplete=true") !== -1) {
			useEventBus().emitEvent("loading", "Registering...");

			try {
				let response = await authenticationService.signIn();

				if (response.isValid()) {
					onAuthenticated(getLoginMethod());
				} else {
					Swal.fire({
						title: "Error",
						html: response.Message?.Reason ?? "An unexpected error occurred signing in. Please try again.",				
						confirmButtonText: "Close",
					});
				}
			} catch (err: any) {
				Swal.fire({
					title: "Error",
					html: "An unexpected error occurred. Please try again.",					
					confirmButtonText: "Close",
				});

				appInsights.trackException(
					{
						exception: err,
						id: "SSOSignInFailed",
						severityLevel: SeverityLevel.Critical,
					},
					{
						Name: "SSOSignInFailed",
						Email: email.value,
						Message: "Error signing in after SSO registration",
					}
				);
			} finally {
				useEventBus().emitEvent("loading-complete");
			}
		} else if (window.location.search.indexOf("ssoError=true") !== -1) {
			let message;
			const responseBase64 = getCookie("X-Sign-In-Response");

			if (responseBase64) {
				const responseJson = atob(responseBase64);
				message = JSON.parse(responseJson)?.Message?.Reason;
			}

			message = message ?? "An unexpected error occurred during single sign on. Please try again.";

			Swal.fire({
				title: "Error",
				html: message,			
				confirmButtonText: "Close",
			});
		}

		useHelpers().removeQueryParams(["ssoComplete", "ssoError"]);
	});

	const emits = defineEmits(["onAuthenticated"]);

	const ssoAction = authenticationService.getApiEndpoint() + "api/Account/SSOChallenge";
	let returnUrl = window.location.href.split("#")[0];
	let index;

	if ((index = returnUrl.indexOf("ssoComplete=true")) !== -1) {
		returnUrl = returnUrl.substring(0, index - 1);
	}

	if ((index = returnUrl.indexOf("ssoError=true")) !== -1) {
		returnUrl = returnUrl.substring(0, index - 1);
	}

	const params = new URLSearchParams(window.location.search.split("#")[0]);
	timezone.value = Intl.DateTimeFormat().resolvedOptions().timeZone;

	async function signUp() {
		if (password.value !== confirmPassword.value) {
			Swal.fire({
				title: "Error",
				text: "Password and Confirm Password do not match.",			
				confirmButtonText: "Close",
			});
			return;
		}

		if (!captcha.value && !authenticationService.isAutomatedTest()) {
			Swal.fire({
				title: "Error",
				text: "You must complete the reCAPTCHA before signing up.",			
				confirmButtonText: "Close",
			});
			return;
		}

		useEventBus().emitEvent("loading", "Registering...");
		console.log(`FirstName: ${firstName.value}, LastName: ${lastName.value}, Email: ${email.value}`);

		const request = new SignUpRequest(
			"USER",
			`${firstName.value} ${lastName.value}`,
			companyName.value,
			email.value,
			password.value,
			window.navigator.platform,
			userFingerprint.value,
			timezone.value,
			captcha.value,
			undefined
		);

		request.showErrorMessagePopUp = false;
		let response = await authenticationService.signUp(request);

		if (response.isValid()) {
			onAuthenticated(LOGIN_METHOD_USERNAME);
		} else {
			recaptcha.value?.reset();
			Swal.fire({
				title: "Error",
				text: response.Message?.Reason ?? "An unexpected error occurred during signup.",			
				confirmButtonText: "Close",
			});
		}

		useEventBus().emitEvent("loading-complete");
	}

	async function verifyCaptcha(response) {
		captcha.value = response;
	}

	async function expireCaptcha() {
		captcha.value = "";
	}

	function getLoginMethod(): string {
		if (document.referrer.toLowerCase().indexOf("google") != -1) {
			return LOGIN_METHOD_GOOGLE;
		} else if (document.referrer.toLowerCase().indexOf("facebook") != -1) {
			return LOGIN_METHOD_FACEBOOK;
		} else if (document.referrer.toLowerCase().indexOf("linkedin") != -1) {
			return LOGIN_METHOD_LINKEDIN;
		}

		return LOGIN_METHOD_USERNAME;
	}

	function toggleSsoFields() {
		showSsoFields.value = !showSsoFields.value;
		if (window.frameElement) {
			window.frameElement.style.height = showSsoFields.value ? "450px" : "750px";
		}
	}

	function openUserSite() {
		const userSiteUrl = import.meta.env.VITE_USER_SITE_URL;
		window.open(userSiteUrl, "_blank");
	}

	function onAuthenticated(method: string) {
		localStorage.removeItem("auth-referrer");
		localStorage.removeItem("auth-action");

		useGtm().trackEvent({
			event: "sign_up",
			category: "auth",
			action: "click",
			method: method,
		});

		appInsights.trackMetric({
			name: "sign_up",
			average: 1,
		});

		if (method === LOGIN_METHOD_USERNAME) {
			isRegistrationComplete.value = true;
			openUserSite();
		} else {
			window.location.href = import.meta.env.VITE_USER_SITE_URL;
		}
	}
</script>

<style scoped>
	body {
		background-color: #fff !important;
		font-family: "Inter_Regular";
	}

	label {
		flex-grow: 0;
		font-family: "Inter_Regular";
		font-size: 14px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: left;
		color: #4e5d78;
		margin-bottom: 4px;
	}

	input {
		width: 100%;
		height: 45px;
		margin-bottom: 16px;
		flex-grow: 0;
		padding: 12px;
		border-radius: 4px;
		box-shadow: 1px 4px 4px 2px rgb(0 0 0 / 5%);
		border: solid 1px #f8f8f8;
		background-color: #ffffff;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: left;
		color: #323b4b;
		opacity: 1;
		font-family: "Inter_Regular";
	}

	.ls-btn {
		width: 100%;
		height: 58px;
		margin-top: 16px;
		border-radius: 10px;
		border: 1px solid transparent;
		padding: 0.375rem 0.75rem;
		font-size: 1rem;
		font-family: "Inter_Regular";
		font-weight: bold;
		text-align: center;
		color: #ffffff;
		cursor: pointer;
		background-color: #346ee0;
	}

	.btn-google {
		background-color: #4285f4;
		color: #ffffff;
	}

	.btn-facebook {
		background-color: rgba(23, 104, 225, 0.85);
		color: white;
	}

	.btn-linked-in {
		background-color: rgba(8, 101, 160, 0.85);
		color: white;
	}

	.btn-other {
		background: #20201f;
		color: white;
	}

	.or-separator {
		font-family: "Inter_Regular";
		font-size: 16px;
		font-weight: 600;
		color: #8a94a6;
		margin-top: 10px;
	}

	.title-join {
		font-family: "Inter_Regular";
		font-style: normal;
		font-weight: 500;
		font-size: 38px;
		line-height: 110%;
		/* or 42px */

		text-align: center;
		letter-spacing: -2px;

		color: #4946ff;
	}

	.title-already-joined {
		font-family: "Inter_Regular";
		font-style: normal;
		font-weight: 500;
		font-size: 38px;
		line-height: 110%;
		/* or 42px */

		text-align: center;
		letter-spacing: -2px;

		color: black;
	}

	.terms {
		color: black;
	}
</style>
