﻿<template>
	<nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-dark"
		 v-bind:class="{ 'navbar-call-ended': callEnded }">
		<div class="ls-container">
			<a :target="callEnded ? '' : '_blank'" :href="userSiteUrl">
				<img src="@/assets/img/ls-video-3D-white-logo.svg"
					 alt="LiveSwitch"
					 id="liveswitch-logo"
					 class="logo"
					 :class="{ 'blue-logo': callEnded }"
					 v-if="!customLogoUrl && !callEnded" />

				<img src="@/assets/img/ls-video-3D-color-logo.svg"
					 alt="LiveSwitch"
					 id="liveswitch-logo"
					 class="logo"
					 :class="{ 'blue-logo': callEnded }"
					 v-if="!customLogoUrl && callEnded" />

				<img :src="customLogoUrl" alt="LiveSwitch" class="custom-logo" v-if="customLogoUrl" />
			</a>
		</div>

		<div class="record-circle-container desktop-element" v-if="joined && meetingHandler.isRecording">
			<span class="circle"></span>
			<span class="text">REC</span>
		</div>

		<div v-show="false"
			 v-if="joined && !callEnded && meetingHandler.localAttendee"
			 style="margin-right: 10px"
			 title="Network Quality">
			<i v-if="meetingHandler.localAttendee.quality == 'low'" class="icon-wifi-2 fa-lg low-quality">
				<div class="tooltip-container row justify-center">
					<div class="tooltip col-auto">Fair</div>
				</div>
			</i>
			<i v-else-if="meetingHandler.localAttendee.quality == 'medium'" class="icon-wifi-3 fa-lg medium-quality">
				<div class="tooltip-container row justify-center">
					<div class="tooltip col-auto">Good</div>
				</div>
			</i>
			<i v-else-if="meetingHandler.localAttendee.quality == 'high'" class="icon-wifi-4 fa-lg high-quality">
				<div class="tooltip-container row justify-center">
					<div class="tooltip col-auto">Great</div>
				</div>
			</i>
			<i v-else-if="meetingHandler.localAttendee.quality == 'unknown'" class="icon-wifi-1 fa-lg unknown-quality">
				<div class="tooltip-container row justify-center">
					<div class="tooltip col-auto">Bad</div>
				</div>
			</i>
		</div>

		<a class="navbar-brand mr-4 waiting-pulse"
		   id="btn-waiting"
		   style="color: white; cursor: pointer"
		   v-if="joined && !callEnded && isMyRoom && haveWaiting"
		   @click="toggleWaitingRoom">
			<i class="fa-solid fa-user-clock fa-lg"></i>
		</a>

		<a 
			class="navbar-brand mr-4"
			id="btn-car-mode"
			:style="{
				'color': props.meetingHandler.carMode ? 'limegreen' : 'white',
				'cursor': 'pointer'
			}"
		   	v-if="joined && !callEnded"
			data-toggle="tooltip"
			:title="props.meetingHandler.carMode ? 'Disable Slow Internet Mode' : 'Enable Slow Internet Mode'"
			data-placement="top"
		   	@click="props.meetingHandler.toggleCarMode()">
			<i class="icon-wifi-speed" style="font-size:32px;margin-right:-8px" v-if="!useHelpers().isMobileBrowser()"></i>
			<i class="fas fa-lg fa-car" v-else></i>
		</a>

		<a class="navbar-brand mr-4"
		   id="btn-settings"
		   style="color: white; cursor: pointer"
		   v-if="joined && !callEnded"
		   @click="toggleSettings">
			<i class="fas fa-cog fa-lg"></i>
		</a>

		<div class="mobile-nav-container mobile-logo" :class="!joined && !callEnded ? 'lobby' : ''">
			<a target="_blank" :href="userSiteUrl">
				<img src="@/assets/img/logo_mobile.png" alt="LiveSwitch Video" v-if="!customLogoUrl"/>
				<img :src="customLogoUrl" alt="LiveSwitch" class="custom-logo" v-if="customLogoUrl" />
			</a>
		</div>
		<div v-if="joined"
			 id="mobile-meeting-menu"
			 class="mobile-nav-container mobile-meeting-menu"
			 @click="useEventBus().emitEvent('toggle-share-modal')">
			{{ meetingSubject ?? "LiveSwitch Video Meeting" }} <i class="fas fa-angle-down"></i>
		</div>

		<div class="mobile-nav-container" v-if="joined">
			<div class="record-circle-container" style="margin-right: 0;" v-if="meetingHandler.isRecording">
				<span class="circle"></span>
				<!--<span class="text">REC</span>-->
			</div>

			<!--<button v-if="!botEnabled"
					id="btn-my-world-mobile"
					type="button"
					class="btn btn-my-world-lg d-flex justify-content-center align-items-center"
					ref="openMyWorld"
					data-toggle="tooltip"
					title="Open MyWorld"
					data-placement="top"
					@click="meetingHandler.openMyWorld()">
				<svg width="30" height="30" viewBox="0 0 30 30" fill="#fff" xmlns="http://www.w3.org/2000/svg">
					<path d="M15.0052 9.13821e-07C12.0381 -0.00103457 9.13725 0.877952 6.66972 2.52577C4.20218 4.1736 2.27878 6.51622 1.14282 9.25733C0.00685227 11.9984 -0.290639 15.0149 0.287972 17.9251C0.866583 20.8353 2.2953 23.5085 4.3934 25.6066C6.49151 27.7047 9.16473 29.1334 12.0749 29.712C14.9851 30.2906 18.0016 29.9931 20.7427 28.8572C23.4838 27.7212 25.8264 25.7978 27.4742 23.3303C29.1221 20.8627 30.001 17.9619 30 14.9948C29.9958 11.0192 28.4147 7.20762 25.6035 4.39646C22.7924 1.58529 18.9808 0.00415875 15.0052 9.13821e-07ZM2.02095 15.0052C2.02189 14.0363 2.13076 13.0705 2.34556 12.1257C2.41885 12.1675 12.5812 17.1099 13.3717 27.8848C10.2364 27.4872 7.35367 25.9597 5.26404 23.5886C3.17441 21.2175 2.02133 18.1657 2.02095 15.0052ZM23.9319 24.4293V23.5288C23.9319 22.7748 23.6324 22.0517 23.0993 21.5185C22.5661 20.9854 21.843 20.6859 21.089 20.6859C20.7669 20.6859 20.4579 20.5579 20.2301 20.3301C20.0023 20.1023 19.8743 19.7933 19.8743 19.4712V16.6283C19.8743 16.4151 19.8324 16.2041 19.7508 16.0072C19.6692 15.8102 19.5497 15.6313 19.399 15.4806C19.2483 15.3299 19.0693 15.2103 18.8724 15.1288C18.6755 15.0472 18.4645 15.0052 18.2513 15.0052H11.7592C11.0752 15.0057 10.414 14.7591 9.89745 14.3108C9.38085 13.8625 9.04356 13.2427 8.94765 12.5654H11.7592C11.9723 12.5654 12.1834 12.5235 12.3803 12.4419C12.5772 12.3603 12.7561 12.2408 12.9068 12.0901C13.0575 11.9394 13.1771 11.7604 13.2587 11.5635C13.3402 11.3666 13.3822 11.1555 13.3822 10.9424V9.79058C13.3822 9.23515 13.6028 8.70247 13.9956 8.30972C14.3883 7.91698 14.921 7.69633 15.4764 7.69633H17.0471C17.8002 7.69634 18.5225 7.39753 19.0555 6.86551C19.5885 6.33349 19.8887 5.61172 19.8901 4.85864V2.96859C21.9069 3.78292 23.6867 5.09102 25.066 6.77273C26.4453 8.45443 27.3799 10.4558 27.7838 12.5929C28.1878 14.7301 28.0482 16.9345 27.3779 19.0036C26.7076 21.0728 25.5281 22.9403 23.9476 24.4345L23.9319 24.4293Z" />
				</svg>
			</button>-->

			
		</div>
		<div class="alert alert-success notification"
			 :class="navAlertHtml.length > 50 && useHelpers().isMobileBrowser() ? 'mobile-notification' : ''"
			 v-show="navAlert"
			 v-html="navAlertHtml"></div>
		<div class="alert alert-success notification" v-show="handRaised">
			<label><strong>{{ handRaisedUserName }}</strong> hand is raised.</label>
			<i class="fas fa-hand-paper fa-spin" style="color: #ffd279; filter: drop-shadow(0 0 1px #fff)"></i>
		</div>
		<div class="alert alert-success notification" id="recording-alert" style="display: none">
			<label id="recording-message-label"></label>
			<span class="elapsed-time-text" style="display: none" id="record-timer">00:00</span>
		</div>

		<a class="btn btn-primary navbar-signin mr-4"
		   id="navbar-signin"
		   v-if="showAuthSection && showLoginButtons"
		   @click="redirectToLogin">
			Sign In
		</a>

		<a class="btn btn-primary navbar-signin mr-4"
		   id="navbar-signup"
		   v-if="showAuthSection && showLoginButtons"
		   @click="redirectToRegistration">
			Sign Up
		</a>

		<span v-if="showAuthSection && isSignedIn && !joined && !callEnded" class="h4" style="margin-top:auto;margin-bottom:auto">Welcome, {{ firstName() }}!</span>
		
		<a class="btn btn-primary navbar-signin mr-4"
		   id="navbar-signin"
		   v-if="showAuthSection && isSignedIn && !joined && !callEnded"
		   @click="redirectToLogout">
			Sign Out
		</a>
	</nav>
</template>
<script lang="ts" setup>
	import { computed, onBeforeMount, onMounted, ref, type PropType, inject } from "vue";
	import useEventBus from "@/composables/useEventBus";
	import type { Attendee } from "@liveswitch/sdk";
	import type LobbyHandler from "@/classes/LobbyHandler";
	import type MeetingHandler from "@/classes/MeetingHandler";
	import useHelpers from "@/composables/useHelpers";
	import type { AuthenticationValidationResponse } from "@/classes/AuthenticationService";
	import useLocalStorage from "@/composables/useLocalStorage";
	import type { IChannelService } from "@/classes/ChannelService";
	import { channelServiceKey } from "@/composables/injectKeys";

	const channelService = inject(channelServiceKey) as IChannelService;
	const navAlerts: { text: string; timeout?: number }[] = [];

	const props = defineProps({
		lobbyHandler: {
			type: Object as PropType<LobbyHandler>,
			required: true,
		},
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
		meetingSubject: {
			type: String,
			required: true,
		},
		infobox: Object as any,
		infoText: Object as any,
		botEnabled: {
			type: Boolean,
			required: false,
		},
	});

	const callEnded = ref(false);
	const joined = ref(false);
	const isSignedIn = ref(false);
	const onReferral = ref(false);
	const showAuthSection = ref(false);
	const userName = ref("");
	const firstName = () =>{
		return userName.value ? userName.value.split(' ')[0] : ''
	}
	const handRaised = ref(false);
	const navAlert = ref(false);
	const navAlertHtml = ref("");
	const handRaisedUserName = ref("");
	const eventBus = useEventBus();
	const userSiteUrl = import.meta.env.VITE_USER_SITE_URL;
	const customLogoUrl = ref("");
	let alertTimeout: number = 0;
	let handRaisedTimeout: number = 0;
	const isMyRoom = ref(false);

	//const canStartBot = computed(() => props.meetingHandler?.role === "HOST" && !props?.meetingHandler.isFreeAccount);
	//const isFreeAccount = computed(() => props?.meetingHandler.isFreeAccount);

	onBeforeMount(() => {
		const params = useHelpers().getParams();
		if (useHelpers().isAuthenticatedUser() && params.has("referral")) {
			onReferral.value = true;
		}
	});

	onMounted(() => {
		eventBus.onEvent("joined", () => {
			joined.value = true;
		});

		eventBus.onEvent("full-auth-validated", (response: AuthenticationValidationResponse) => {
			userName.value = response.DisplayName ?? response.Email ?? "Guest";
			isSignedIn.value = response.Email != undefined;
			showAuthSection.value = true;
		});

		eventBus.onEvent("leave-call", () => {
			joined.value = false;
			callEnded.value = true;
		});

		eventBus.onEvent("nav-alert", (alert: { text: string; timeout?: number }) => {
			showAlert(alert);
		});

		eventBus.onEvent("hand-raised", (attendee: Attendee) => {
			handRaised.value = true;

			if (props.meetingHandler.localAttendeeId == attendee.id) {
				handRaisedUserName.value = "Your";
			} else {
				let possesion: string = "'s";
				let lastCharacter = attendee.displayName[attendee.displayName.length - 1];

				if (lastCharacter == "s") {
					possesion = "'";
				}

				handRaisedUserName.value = `${attendee.displayName}${possesion}`;
			}

			clearTimeout(handRaisedTimeout);

			handRaisedTimeout = setTimeout(() => {
				handRaised.value = false;
			}, 3000);
		});

		eventBus.onEvent("hand-lowered", () => {
			handRaised.value = false;
		});

		eventBus.onEvent("custom-logo-updated", (logoUrl: string) => {
			customLogoUrl.value = logoUrl;
		});

		isMyRoom.value = useLocalStorage().getChannel()?.MeetingOwner ?? false;
		eventBus.onEvent("host-signed-in-mid-meeting", async () => {
			isMyRoom.value = useLocalStorage().getChannel()?.MeetingOwner ?? false;
		});
	});

	const haveWaiting = computed(() => {
		return props.lobbyHandler.lobbyUserCount > 0;
	});

	function showAlert(alert: { text: string; timeout?: number }) {
		if (alertTimeout > 0) {
			navAlerts.push(alert);
			return;
		}

		navAlertHtml.value = alert.text;
		navAlert.value = true;

		if (!alert.timeout) {
			alert.timeout = 5000;
		}

		alertTimeout = setTimeout(() => {
			navAlert.value = false;
			navAlertHtml.value = "";
			alertTimeout = 0;

			const nextAlert = navAlerts.splice(0, 1);

			if (nextAlert.length > 0) {
				showAlert(nextAlert[0]);
			}
		}, alert.timeout);
	}

	function toggleWaitingRoom() {
		props.meetingHandler.waitingRoomOpen = !props.meetingHandler.waitingRoomOpen;
	}

	function toggleSettings() {
		eventBus.emitEvent("settings-open", "settings");
	}

	function redirectToLogin() {
		window.location.assign("/login");
	}

    function redirectToLogout() {
        window.location.href = `${import.meta.env.VITE_USER_SITE_URL}/Account/SignOut`;
    }

	function redirectToRegistration() {
		window.location.assign("/register");
	}

	const showLoginButtons = computed(() => {
		return !isSignedIn.value && !joined.value && !callEnded.value && !onReferral.value;
	});
</script>

<style lang="scss" scoped>
	#liveswitch-logo {
		height: 37px;
	}

	header {
		height: 72px;
	}

	nav {
		height: 100%;
	}
	.mobile nav{
		height: 48px;
	}
	.ls-container {
		flex: 1;
	}
	.logo {
		cursor: pointer;
	}

	.custom-logo {
		max-width: 156px;
		max-height: 56px;
		cursor: pointer;
	}

	.blue-logo {
	}

	.mobile-nav-container {
		display: none;
	}

	.navbar {
		justify-content: space-between !important;
		padding: 0.5rem 1rem;
	}

	.alert-success {
		font-family: "Inter_Medium";
		margin: 0 auto;
		position: absolute;
		width: fit-content;
		width: -moz-fit-content;
		width: -webkit-fit-content;
		z-index: 10000;
		left: 0;
		right: 0;
		font-size: 18px;
		text-align: center;
		border-radius: 5px;
		border: none;
		background-color: black;
		padding: 10px 18px;
		top: 10px;
		color: white;
	}

	.alert-success .fa-hand-paper {
		margin-left: 12px;
	}

	@media (min-width: 320px) and (max-width: 767px) {
		body {
			min-width: 100% !important;
			min-height: 100% !important;
			max-width: 100% !important;
		}

		.ls-container {
			display: none;
		}

		header {
			height: 65px;
		}

		#btn-settings {
			display: none;
		}

		.mobile-nav-container {
			display: flex;
		}

		.mobile-nav-container.mobile-logo.lobby {
			flex: 1;
		}

		.mobile-meeting-menu {
			flex: 1 1 auto;
			align-items: center;
			justify-content: center;
			color: white;
			font-family: "Inter_Light";
		}

		.mobile-meeting-menu .fa-angle-down {
			margin-left: 6px;
			margin-top: 2px;
		}

		.mobile-logo img {
			max-height: 32px;
		}

		.btn-leave {
			width: unset;
			height: unset;
			margin: 0;
		}

		.navbar {
			width: 100%;
			height: unset !important;
			padding: 0.5rem !important;
			box-shadow: 0px 1px 1px #222;
		}

		.navbar-call-ended {
			box-shadow: 0px 1px 1px #bbb;
		}

		.btn-my-world-lg {
			background-color: #346ee0 !important;
			height: 44px;
			width: 44px;
			padding: 8px !important;
			border-radius: 50% !important;
		}

		.bot {
			
			height: 44px;
			width: 44px;
			padding: 2px !important;
			border-radius: 50% !important;
		}

		.bot.hover {
			border: 0px solid #ffd279;
		}

		

		.alert-success {
			position: fixed;
		}

		.mobile-notification {
			font-size: smaller;
		}
	}

	.record-circle-container {
		margin-top: 0;
		margin-right: 15px;
		display: flex;
		align-items: center;
		background: #f44a4a;
		text-align: center;
		border-radius: 5px;
		padding: 8px;
	}

	.record-circle-container .circle {
		height: 16px;
		width: 16px;
		border-radius: 50%;
		background-color: white;
	}

	.record-circle-container .text {
		font-family: "Inter_Light" !important;
		margin-left: 5px;
		font-size: 16px;
		font-weight: 500;
	}

	.navbar-signin {
		margin-left: 10px;
	}

	.navbar-signin:hover {
		color: #ccc;
	}

	#btn-waiting.waiting-pulse {
		-webkit-animation: pulse 1.5s infinite;
	}

	@-webkit-keyframes pulse {
		0% {
			color: #fff;
		}
		50% {
			color: #346ee0;
		}
		100% {
			color: #fff;
		}
	}

	.low-quality,
	.unknown-quality {
		color: red;
	}

	.medium-quality {
		color: darkorange;
	}

	.high-quality {
		color: greenyellow;
	}

	.low-quality,
	.unknown-quality,
	.medium-quality,
	.high-quality {
		.tooltip-container {
			position: absolute;
			width: 50px;
			top: 45px;
			pointer-events: none;
			user-select: none;
			z-index: 1;
			display: flex;
			justify-content: center;
			.tooltip {
				background: #0f1218;
				padding: 2px 8px;
				border-radius: 10px;

				color: white;
				opacity: 0;
				transition: 0.2s display;
			}

			.tooltip-persistant {
				opacity: 1;
				font-size: 0.7rem;
			}
		}
		&:hover .tooltip {
			opacity: 1;
		}
	}
</style>
