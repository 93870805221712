﻿import useEventBus from "@/composables/useEventBus";
import useHelpers from "@/composables/useHelpers";
import type { Attendee, Meeting } from "@liveswitch/sdk";
import type MeetingHandler from "./MeetingHandler";
import { reactive } from "vue";

export default class UserActionHandler {
	private _meeting?: Meeting;
	private _meetingHandler: MeetingHandler;
	private _attendeePaused: any;
	private _attendeePauseTimeouts: any;
	private _attendeeResumeTimeouts: any;
	private _blurTime: number;
	private _unblurTime: number;

	constructor(meeting: Meeting | undefined, meetingHandler: MeetingHandler, blurTime = 2000, unblurTime = 2000) {
		this._meeting = meeting;
		this._meetingHandler = meetingHandler;
		this._blurTime = blurTime;
		this._unblurTime = unblurTime;
		this._attendeePaused = reactive({});
		this._attendeePauseTimeouts = reactive({});
		this._attendeeResumeTimeouts = reactive({});
	}

	setMeeting(meeting: Meeting) {
		this._meeting = meeting;

		useEventBus().onEvent("media-removed", (e: any) => {
			const attendeeId = e.element?.attendee?.id;
			this.clearPauseTimeout(attendeeId);
			this.clearResumeTimeout(attendeeId);
			this._attendeePaused[attendeeId] = false;
		});
	}

	getDisplayName(attendeeId: string): string {
		const attendee = this.getAttendee(attendeeId);

		if (attendee?.displayName) {
			return attendee.displayName;
		}

		return "Guest Video"; // TODO: Remove when attendees fixed
	}

	async toggleUserAudio(attendeeId: string): Promise<void> {
		const attendee = this.getAttendee(attendeeId);

		if (attendee) {
			if (attendee.id == this._meeting?.localAttendee.id) {
				await this._meetingHandler.toggleLocalAudio();
				return;
			}

			if (attendee.type == "SIPINBOUND") {
				if (attendee.isAudioMuted) {
					await attendee.unmuteAudio();
				} else {
					await attendee.muteAudio();
				}
			} else {
				if (attendee.isAudioUnmuteDisabled) {
					await attendee.enableAudioUnmute();
				} else {
					if (attendee.role !== "HOST" && attendee.role !== "MODERATOR") {
						await attendee.disableAudioUnmute();
					}

					await attendee.muteAudio();
				}
			}

			if (this._meetingHandler.isMeetingAudioDisabled) {
				// if its disabled check if all attendees have been enabled if it is then switch the flag
				// call the meeting enable function
				if (
					this._meetingHandler.attendees.filter((a) => a.isAudioUnmuteDisabled && a.role == "ATTENDEE")
						.length == 0
				) {
					await this._meeting?.unmuteAudioOnJoin();
					await this._meeting?.enableAudioUnmuteOnJoin();
					await this._meeting?.enableAudioUnmute();
					this._meetingHandler.meetingAudioDisabled = false;
				}
			} else {
				// if its enabled check if all attendees have been disabled if it is then switch the flag
				// call the meeting disabled function
				if (
					this._meetingHandler.attendees.filter((a) => !a.isAudioUnmuteDisabled && a.role == "ATTENDEE")
						.length == 0
				) {
					await this._meeting?.muteAudioOnJoin();
					await this._meeting?.disableAudioUnmuteOnJoin();
					await this._meeting?.disableAudioUnmute();
					this._meetingHandler.meetingAudioDisabled = true;
				}
			}
		}
	}

	async toggleUserVideo(attendeeId: string): Promise<void> {
		const attendee = this.getAttendee(attendeeId);

		if (attendee) {
			if (attendee.id == this._meeting?.localAttendee.id) {
				await this._meetingHandler.toggleLocalVideo();
				return;
			}

			if (attendee.isVideoUnmuteDisabled) {
				await attendee.enableVideoUnmute();
			} else {
				if (attendee.role !== "HOST" && attendee.role !== "MODERATOR") {
					await attendee.disableVideoUnmute();
				}

				await attendee.muteVideo();
			}

			if (this._meetingHandler.isMeetingVideoDisabled) {
				// if its disabled check if all attendees have been enabled if it is then switch the flag
				// call the meeting enable function
				if (
					this._meetingHandler.attendees.filter((a) => a.isVideoUnmuteDisabled && a.role == "ATTENDEE")
						.length == 0
				) {
					await this._meeting?.unmuteVideoOnJoin();
					await this._meeting?.enableVideoUnmuteOnJoin();
					await this._meeting?.enableVideoUnmute();
					this._meetingHandler.meetingVideoDisabled = false;
				}
			} else {
				// if its enabled check if all attendees have been disabled if it is then switch the flag
				// call the meeting disabled function
				if (
					this._meetingHandler.attendees.filter((a) => !a.isVideoUnmuteDisabled && a.role == "ATTENDEE")
						.length == 0
				) {
					await this._meeting?.muteVideoOnJoin();
					await this._meeting?.disableVideoUnmuteOnJoin();
					await this._meeting?.disableVideoUnmute();
					this._meetingHandler.meetingVideoDisabled = true;
				}
			}
		}
	}

	isUserAudioMuted(attendeeId: string): boolean {
		const attendee = this.getAttendee(attendeeId);

		if (attendee) {
			return attendee.isAudioMuted;
		}

		return false;
	}

	isUserAudioDisabled(attendeeId: string): boolean {
		const attendee = this.getAttendee(attendeeId);

		if (attendee) {
			return attendee.isAudioUnmuteDisabled;
		}

		return false;
	}

	isUserVideoMuted(attendeeId: string): boolean {
		const attendee = this.getAttendee(attendeeId);

		if (attendee) {
			return attendee.isVideoMuted;
		}

		return false;
	}

	isUserVideoDisabled(attendeeId: string): boolean {
		const attendee = this.getAttendee(attendeeId);

		if (attendee) {
			return attendee.isVideoUnmuteDisabled;
		}

		return false;
	}

	isUserModerator(attendeeId: string): boolean {
		const attendee = this.getAttendee(attendeeId);

		if (attendee) {
			return attendee.role === "MODERATOR";
		}

		return false;
	}

	isSipUser(attendeeId: string): boolean {
		let sip = false;
		const attendee = this.getAttendee(attendeeId);

		if (attendee) {
			sip = attendee.type == "SIPINBOUND";
		}

		return sip;
	}

	canUserShare(attendeeId: string) {
		const attendee = this.getAttendee(attendeeId);

		if (attendee) {
			return attendee.permissions.includes("ATTENDEE:SendDisplayMedia");
		}

		return false;
	}

	async toggleModerator(attendeeId: string) {
		const attendee = this.getAttendee(attendeeId);

		if (attendee) {
			if (attendee.role === "MODERATOR") {
				await attendee.setRole("ATTENDEE");
			} else if (attendee.role === "ATTENDEE" && attendee.type != "SIPINBOUND") {
				await attendee.setRole("MODERATOR");
			}
		}
	}

	async toggleScreenShare(attendeeId: string) {
		const attendee = this.getAttendee(attendeeId);

		if (attendee) {
			if (this.canUserShare(attendeeId)) {
				await attendee.removePermission("SendDisplayMedia");
			} else {
				await attendee.addPermission("SendDisplayMedia");
			}
		}
	}

	async kickUser(attendeeId: string) {
		const attendee = this.getAttendee(attendeeId);

		if (attendee) {
			await attendee.kick();
		}
	}

	pinUser(attendeeId: string) { }

	handRaised(event: any) {
		useEventBus().emitEvent("hand-raised", event.attendee);
	}

	handLowered(event: any) {
		useEventBus().emitEvent("hand-lowered", event.attendee);
	}

	videoPaused(event: any) {
		if (this._meeting?.localAttendee.id == event.attendee.id) {
			this._meetingHandler.appInsights?.trackEvent(
				{
					name: "AttendeeVideoPaused",
				},
				useHelpers().getLoggingProperties("AttendeeVideoPaused", "AttendeeVideoPaused", {
					networkStatusReceive: event.attendee.networkStatusReceive,
					networkStatusSend: event.attendee.networkStatusSend,
					quality: event.attendee.quality,
				})
			);
			console.warn(
				`Local video has been paused. networkStatusReceive ${event.attendee.networkStatusReceive} - networkStatusSend ${event.attendee.networkStatusSend} - quality ${event.attendee.quality} - time ${new Date()}`
			);
		}
		this.setPauseTime(event.attendee.id);
	}

	videoResumed(event: any) {
		if (this._meeting?.localAttendee.id == event.attendee.id) {
			console.warn(
				`Local video has resumed. networkStatusReceive ${event.attendee.networkStatusReceive} - networkStatusSend ${event.attendee.networkStatusSend} - quality ${event.attendee.quality}- time ${new Date()}`
			);
			this._meetingHandler.appInsights?.trackEvent(
				{
					name: "AttendeeVideoResumed",
				},
				useHelpers().getLoggingProperties("AttendeeVideoResumed", "AttendeeVideoResumed", {
					networkStatusReceive: event.attendee.networkStatusReceive,
					networkStatusSend: event.attendee.networkStatusSend,
					quality: event.attendee.quality,
				})
			);
		}
		this.setResumeTime(event.attendee.id);
	}

	setPauseTime(attendeeId: string) {
		const attendee = this.getAttendee(attendeeId);
		if (attendee) {
			this._attendeePauseTimeouts[attendee.id] = window.setTimeout(() => {
				this._attendeePaused[attendee.id] = true;
				this.clearPauseTimeout(attendee.id);
			}, this._blurTime);
		}
	}

	clearPauseTimeout(attendeeId: string) {
		if (this._attendeePauseTimeouts[attendeeId]) {
			window.clearTimeout(this._attendeePauseTimeouts[attendeeId]);
			delete this._attendeePauseTimeouts[attendeeId];
		}
	}

	setResumeTime(attendeeId: string) {
		const attendee = this.getAttendee(attendeeId);
		if (attendee) {
			if (this._attendeePauseTimeouts[attendee.id]) {
				// unpause the attendee
				this.clearPauseTimeout(attendee.id);
			} else {
				// start the X second timeout to un blur.
				this._attendeeResumeTimeouts[attendee.id] = window.setTimeout(() => {
					// after the X seconds delete the timeout to unblur
					this.clearResumeTimeout(attendee.id);
				}, this._unblurTime);
			}
			this._attendeePaused[attendee.id] = false;
		}
	}

	clearResumeTimeout(attendeeId: string) {
		if (this._attendeeResumeTimeouts[attendeeId]) {
			window.clearTimeout(this._attendeeResumeTimeouts[attendeeId]);
			delete this._attendeeResumeTimeouts[attendeeId];
			// if resume timeout happens then we should also set paused to false.
			this._attendeePaused[attendeeId] = false;
		}
	}

	blurVideo(attendeeId: string): boolean {
		const attendee = this.getAttendee(attendeeId);
		if (attendee && !attendee.isVideoMuted) {
			if (this._attendeePaused[attendee.id] || this._attendeeResumeTimeouts[attendee.id]) {
				return true;
			}
		}
		return false;
	}

	isHandRaised(attendeeId: string) {
		const attendee = this.getAttendee(attendeeId);
		let handRaised = false;

		if (attendee) {
			handRaised = attendee.isHandRaised();
		}

		return handRaised;
	}

	getAttendee(attendeeId: string): Attendee | undefined {
		if (!this._meeting) {
			return undefined;
		}

		if (attendeeId == this._meeting.localAttendee?.id) {
			return this._meeting.localAttendee;
		}

		var attendee = this._meeting.attendees?.find((x) => x.id == attendeeId);
		return attendee;
	}
}
