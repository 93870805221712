﻿<template>
	<div 
		v-if="!meetingHandler.meeting.attendees.isStarted"
		class="alert alert-danger" 
		role="alert">
		It looks like we experienced an error when loading the users list. Please refresh your browser and rejoin the meeting to use this feature.
	</div>
	<div v-else class="participant-list">
		<div class="list-group participant-list-container">
			<div class="list-group-item participant-box">
				<user-vue
					v-if="true"
					:key="meetingHandler.localAttendee?.id"
					:current-user-role="currentUserRole"
					:attendee="meetingHandler.localAttendee"
					:meeting-handler="meetingHandler"
					:lobby-handler="lobbyHandler"
					:video="getUserMedia(meetingHandler.localAttendee)"></user-vue>
				<user-vue
					v-for="attendee in users"
					:key="attendee.id"
					:current-user-role="currentUserRole"
					:attendee="attendee"
					:meeting-handler="meetingHandler"
					:lobby-handler="lobbyHandler"
					:video="getUserMedia(attendee)"></user-vue>
			</div>
		</div>
		<div class="d-flex participant-host-actions">
			<button
				id="btn-mute-all-audio"
				type="button"
				class="btn btn-participant"
				@click="toggleMeetingAudio"
				v-if="canToggleMeetingMedia">
				{{ meetingHandler.meetingAudioDisabled ? "Unmute all" : "Mute all" }}
			</button>
			<button
				id="btn-mute-all-video"
				type="button"
				class="btn btn-participant"
				@click="toggleMeetingVideo"
				v-if="canToggleMeetingMedia">
				{{ meetingHandler.meetingVideoDisabled ? "Turn on video" : "Turn off video" }}
			</button>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import type { PropType } from "vue";
	import UserVue from "./User.vue";
	import { computed } from "vue";
	import type MeetingHandler from "@/classes/MeetingHandler";
	import useEventBus from "../composables/useEventBus";
	import type { Attendee } from "@liveswitch/sdk";
import type LobbyHandler from "@/classes/LobbyHandler";

	const props = defineProps({
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
		lobbyHandler: {
			type: Object as PropType<LobbyHandler>,
			required: true
		}
	});

	const currentUserRole = computed(() => props.meetingHandler.role);

	const users = computed(() => {
		let users = props.meetingHandler.attendees?.filter((x) => x.id != props.meetingHandler.localAttendeeId);
		users = users?.filter((x) => x.displayName != props.meetingHandler.ninjaDisplayName);
		return users;
	});

	const canToggleMeetingMedia = computed(
		() => props.meetingHandler.role === "HOST" || props.meetingHandler.role === "MODERATOR"
	);

	function toggleMeetingAudio() {
		useEventBus().emitEvent("mute-all-audio");
	}

	function toggleMeetingVideo() {
		useEventBus().emitEvent("mute-all-video");
	}

	function getUserMedia(attendee: Attendee) {
		const media = props.meetingHandler.visibleUserMedias.find((x) => x.attendee?.id == attendee.id);
		return media;
	}
</script>

<style scoped>
	.participant-list {
		height: calc(100% - 48px);
		display: flex;
		flex-direction: column;
	}

	.participant-list-container {
		height: calc(100vh - 280px);
		overflow-y: auto;
	}

	button.btn.btn-participant {
		width: 50%;
		margin: 0 5px;
		padding: 10px 12px;
		border-radius: 10px;
		background-color: #f3f3f3;
		font-size: 11pt;
		font-family: "Inter_SemiBold";
	}

	.btn-participant:hover {
		background-color: #8a94a6;
	}
	.participant-host-actions {
		margin-top: 1em;
	}
</style>
