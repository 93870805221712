﻿<template>
	<div class="myworldcontainer" id="my-world-editor">
		<!-- Modal error for MyWorld-->
		<div
			class="modal fade"
			id="myworld-modal-error"
			tabindex="-1"
			role="dialog"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-body myworld-modal-error">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
						<h5>Something went wrong</h5>
						<ul>
							<li v-for="errorMessage in errorMessages">
								{{ errorMessage }}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="my-world-mobile-header-container">
			<div class="my-world-mobile-header-row">
				<div class="col-2"></div>
				<div class="col my-world-header inter_medium">
					<button type="button" class="btn btn-my-world-lg d-flex justify-content-center align-items-center">
						<svg width="30" height="30" viewBox="0 0 30 30" fill="#fff" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M15.0052 9.13821e-07C12.0381 -0.00103457 9.13725 0.877952 6.66972 2.52577C4.20218 4.1736 2.27878 6.51622 1.14282 9.25733C0.00685227 11.9984 -0.290639 15.0149 0.287972 17.9251C0.866583 20.8353 2.2953 23.5085 4.3934 25.6066C6.49151 27.7047 9.16473 29.1334 12.0749 29.712C14.9851 30.2906 18.0016 29.9931 20.7427 28.8572C23.4838 27.7212 25.8264 25.7978 27.4742 23.3303C29.1221 20.8627 30.001 17.9619 30 14.9948C29.9958 11.0192 28.4147 7.20762 25.6035 4.39646C22.7924 1.58529 18.9808 0.00415875 15.0052 9.13821e-07ZM2.02095 15.0052C2.02189 14.0363 2.13076 13.0705 2.34556 12.1257C2.41885 12.1675 12.5812 17.1099 13.3717 27.8848C10.2364 27.4872 7.35367 25.9597 5.26404 23.5886C3.17441 21.2175 2.02133 18.1657 2.02095 15.0052ZM23.9319 24.4293V23.5288C23.9319 22.7748 23.6324 22.0517 23.0993 21.5185C22.5661 20.9854 21.843 20.6859 21.089 20.6859C20.7669 20.6859 20.4579 20.5579 20.2301 20.3301C20.0023 20.1023 19.8743 19.7933 19.8743 19.4712V16.6283C19.8743 16.4151 19.8324 16.2041 19.7508 16.0072C19.6692 15.8102 19.5497 15.6313 19.399 15.4806C19.2483 15.3299 19.0693 15.2103 18.8724 15.1288C18.6755 15.0472 18.4645 15.0052 18.2513 15.0052H11.7592C11.0752 15.0057 10.414 14.7591 9.89745 14.3108C9.38085 13.8625 9.04356 13.2427 8.94765 12.5654H11.7592C11.9723 12.5654 12.1834 12.5235 12.3803 12.4419C12.5772 12.3603 12.7561 12.2408 12.9068 12.0901C13.0575 11.9394 13.1771 11.7604 13.2587 11.5635C13.3402 11.3666 13.3822 11.1555 13.3822 10.9424V9.79058C13.3822 9.23515 13.6028 8.70247 13.9956 8.30972C14.3883 7.91698 14.921 7.69633 15.4764 7.69633H17.0471C17.8002 7.69634 18.5225 7.39753 19.0555 6.86551C19.5885 6.33349 19.8887 5.61172 19.8901 4.85864V2.96859C21.9069 3.78292 23.6867 5.09102 25.066 6.77273C26.4453 8.45443 27.3799 10.4558 27.7838 12.5929C28.1878 14.7301 28.0482 16.9345 27.3779 19.0036C26.7076 21.0728 25.5281 22.9403 23.9476 24.4345L23.9319 24.4293Z" />
						</svg>
					</button>
					MyWorld
				</div>
				<div class="col-2 my-world-close">
					<i class="fal fa-times fa-large" @click="eventBus.emitEvent('toggle-local-my-world', false)"></i>
				</div>
			</div>
			<div class="my-world-mobile-tabs">
				<div class="col-6 tab" v-bind:class="{ active: currentNavbarItem === 0 }">
					<a href="#" @click="currentNavbarItem = 0"> Edit </a>
				</div>
				<div class="col-6 tab" v-bind:class="{ active: currentNavbarItem === 1 }">
					<a href="#" @click="currentNavbarItem = 1"> Preview </a>
				</div>
			</div>
		</div>
		<div class="row" id="my-world-row">
			<div
				class="col-12 col-sm-12 col-md-6 col-lg-6 my-world-edit-col"
				id="my-world-left-panel"
				v-show="!useHelpers().isMobileBrowser() || currentNavbarItem == 0">
				<div class="card myworld-main-card">
					<div class="card-body">
						<div v-show="updatedSuccessfully" class="alert alert-success text-center" role="alert">
							<h5 class="alert-heading">MyWorld updated successfully.</h5>
						</div>
						<div class="d-flex align-content-center my-world-update-header" style="margin: 0">
							<div class="mr-auto d-flex flex-column">
								<h4 class="inter_medium">Help People Get To Know You</h4>
								<label class="inter-grey-text">Enter what you want - these are all optional.</label>
							</div>
							<div class="form-group text-center align-content-start">
								<button
									v-if="!isUpdating"
									class="ls-btn ls-btn-blue btn-my-world-update"
									@click="update"
									v-bind:disabled="isUpdating || !dirty || !valid"
									v-bind:readonly="isUpdating || !dirty || !valid">
									Update
								</button>
								<button
									v-else
									class="ls-btn ls-btn-blue btn-my-world-update"
									type="button"
									v-bind:disabled="isUpdating">
									<span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
								</button>
							</div>
						</div>
						<h5 class="inter_medium mt-3">About You</h5>
						<div class="form-group mt-3">
							<label class="myworldlabels">Name</label>
							<input
								v-model="myWorldModel.name"
								type="text"
								class="form-control inter-font-16 myworld-input"
								id="myworld-name-input"
								placeholder="-"
								maxlength="50" />
						</div>
						<div class="form-group mt-3">
							<label class="myworldlabels">Location</label>
							<input
								v-model="myWorldModel.location"
								type="text"
								class="form-control inter-font-16 myworld-input"
								id="myworld-location-input"
								placeholder="-"
								maxlength="50" />
						</div>
						<div class="form-group mt-3">
							<label class="myworldlabels">School</label>
							<input
								v-model="myWorldModel.school"
								type="text"
								class="form-control inter-font-16 myworld-input"
								id="myworld-school-input"
								placeholder="-"
								maxlength="50" />
						</div>
						<div class="form-group mt-3">
							<label class="myworldlabels">Work</label>
							<input
								v-model="myWorldModel.work"
								type="text"
								class="form-control inter-font-16 myworld-input"
								id="myworld-work-input"
								placeholder="-"
								maxlength="50" />
						</div>
						<div class="form-group mt-3">
							<label class="myworldlabels">Job Title</label>
							<input
								v-model="myWorldModel.jobTitle"
								type="text"
								class="form-control inter-font-16 myworld-input"
								id="myworld-jobtitle-input"
								placeholder="-"
								maxlength="50" />
						</div>

						<div class="mt-5">
							<div v-if="myWorldModel.images.length > 0">
								<h5 class="inter_medium">Photos</h5>
								<!-- Modal for adding photos -->
								<div class="modal fade" tabindex="-1" role="dialog" :class="{ show: uploadImage }">
									<div class="modal-dialog" role="document">
										<div class="modal-content modal-upload-image">
											<div class="modal-body">
												<h4 class="modal-title inter_medium text-center">
													Bring Pictures Into MyWorld
												</h4>
												<div class="text-center" style="width: 100%; height: 100%">
													<img
														v-if="currentImageUrl != ''"
														v-bind:src="currentImageUrl"
														id="myworld-image-modal-add"
														style="width: 100%; height: 100%" />
													<img v-else src="../assets/img/upload_image.png" class="mt-4" />
													<!-- <div v-else id="div-no-image" class="text-center">No Image</div> -->
												</div>
												<label class="myworldlabels mt-3">Upload File</label>
												<div class="position-relative">
													<input
														type="text"
														id="myworld-input-image-modal-fake"
														class="form-control myworld-input"
														placeholder="Browse photos on your device"
														v-model="newImageFile"
														@click="$refs.newImage.click()" />
													<span @click="$refs.newImage.click()">
														<i
															class="fas fa-cloud-upload-alt clickable position-absolute upload-icon"></i>
													</span>
													<input
														type="file"
														id="myworld-input-image-modal"
														class="form-control myworld-input"
														accept="image/*"
														ref="newImage"
														v-on:change="imageSelected"
														style="display: none" />
												</div>
											</div>
											<div class="text-center mt-3">
												<button
													style="display: none"
													id="btn-upload-image-confirm"
													type="button"
													v-bind:class="'btn btn-primary'"
													:disabled="currentImageUrl == ''">
													Confirm
												</button>
												<br />
												<button
													id="btn-upload-image-cancel"
													type="button"
													class="btn btn-secondary-outline mt-3"
													@click="uploadImage = false">
													Cancel
												</button>
											</div>
										</div>
									</div>
								</div>

								<div class="mt-2 photo-row" style="margin-bottom: 15px">
									<div v-for="(photo, index) in myWorldModel.images" class="photo-container">
										<div
											class="add-photo-button clickable"
											v-if="photo.imageUrl == ''"
											@click="addImage(photo)">
											<div class="opacity05">
												<i
													class="fa fa-plus fa-lg myworld-add-image-button"
													v-if="photo.imageUrl == ''"></i>
												<br />
												Add image
											</div>
										</div>
										<div class="myworld-image-div clickable" v-else v-on:click="editImage(photo)">
											<i class="fas fa-pencil-alt myworld-delete-image-button"></i>
											<img
												class="myworld-image"
												v-bind:src="photo.imageUrl"
												v-bind:title="photo.pinName"
												v-if="photo.imageUrl != ''" />
										</div>
									</div>
								</div>
							</div>

							<!-- Modal for creating fun facts -->
							<div
								class="modal fade custom-modal"
								:class="{ show: showFactModal }"
								tabindex="-1"
								role="dialog">
								<div class="modal-dialog modal-lg modal-select-fun-fact-dialog" role="document">
									<div class="modal-content modal-select-fun-facts">
										<div class="modal-body">
											<div class="d-flex fun-fact-header-container">
												<h4
													class="modal-title inter_medium text-center fun-fact-question-title">
													Select and answer the question below to let people know a little
													more about you
												</h4>
												<i class="fal fa-times close" @click="showFactModal = false"></i>
											</div>
											<div class="form-group">
												<label for="modal-fun-fact-question mt-3" class="myworldlabels">
													Question
												</label>
												<div class="position-relative dropdown" id="question-dropdown-div">
													<Multiselect
														class="input-invite"
														v-model="newFactQuestion"
														:options="questions"
														searchable
														ref="factQuestionsMultisel"
														placeholder="Choose question or write your own"
														:append-new-option="false"
														:create-option="true"
														no-options-text=""
														no-results-text=""
														:clearOnBlur="false" />
												</div>
												<!-- <div class="alert alert-danger mb-1" role="alert" v-if="funFactQuestionError">
													The fun fact qu
												</div> -->
											</div>
											<div class="form-group mt-3">
												<label for="modal-fun-inter-font-16" class="myworldlabels">
													Answer
												</label>
												<textarea
													ref="newFactArea"
													class="form-control myworld-input"
													id="modal-fun-inter-font-16"
													v-model="newFactAnswer"
													placeholder="Answer"
													maxlength="500"></textarea>
											</div>
											<div class="d-flex justify-content-end">
												<button
													type="submit"
													class="btn btn-primary save-fun-fact-button inter-font-18 float-right mt-4"
													@click="saveFact"
													v-bind:disabled="!isValidFunFact()">
													Save
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>

							<h5 class="inter_medium mt-4">Fun Facts</h5>
							<div v-for="(fact, index) in myWorldModel.funFacts">
								<h5 class="myworldlabels mt-3">Fact {{ index + 1 }}</h5>
								<div v-if="fact.pinName != ''" class="card myworld-input">
									<div class="card-body">
										<div class="d-flex fact-header">
											<h6 class="fact-question">
												{{ fact.pinName }}
											</h6>
											<span v-on:click="deleteFact(index)"
												><i
													v-if="fact.pinName !== ''"
													class="fas fa-times myworld-delete-fact-button clickable"></i
											></span>
										</div>
										<label class="myworldviewlabels inter-font-16" id="fun-fact">
											{{ fact.pinContent }}
										</label>
									</div>
								</div>
								<div v-else class="card myworld-input myworld-empty-funfact" @click="addNewFact">
									<div class="card-body myworld-empty-funfact-body">
										<div class="d-flex fact-header">
											<h6 class="inter-font-16 opacity05 mr-4">
												Answer a pre-written question or create your own
												<span @click="addNewFact">
													<i class="fa fa-plus myworld-add-fact-button clickable"></i>
												</span>
											</h6>
										</div>
									</div>
								</div>
							</div>
						</div>

						<h5 class="inter_medium mt-4">Social Media Accounts</h5>
						<div class="form-group mt-3">
							<label class="myworldlabels" for="linkedin-account-input">LinkedIn</label>
							<input
								v-model="myWorldModel.linkedinLink"
								type="text"
								class="form-control inter-font-16 myworld-input"
								id="linkedin-account-input"
								placeholder="-" />
							<div
								v-show="
									myWorldModel.linkedinLink !== null &&
									myWorldModel.linkedinLink !== '' &&
									!isValidLink(myWorldModel.linkedinLink)
								"
								class="alert alert-danger mt-1"
								role="alert"
								v-cloak>
								Please enter a valid link. A valid link will look something like this:<br>
								<a href = "https://www.linkedin.com/company/liveswitch-inc/" target="_blank">https://www.linkedin.com/company/liveswitch-inc/</a>
							</div>
						</div>
						<div class="form-group mt-3">
							<label class="myworldlabels" for="facebook-account-input">Facebook</label>
							<input
								v-model="myWorldModel.facebookLink"
								type="text"
								class="form-control inter-font-16 myworld-input"
								id="facebook-account-input"
								placeholder="-" />
							<div
								v-show="
									myWorldModel.facebookLink !== null &&
									myWorldModel.facebookLink !== '' &&
									!isValidLink(myWorldModel.facebookLink)
								"
								class="alert alert-danger mt-1"
								role="alert"
								v-cloak>
								Please enter a valid link. A valid link will look something like this:<br>
								<a href = "https://www.facebook.com/liveswitchinc/" target="_blank">https://www.facebook.com/liveswitchinc/</a>
							</div>
						</div>
						<div class="form-group mt-3">
							<label class="myworldlabels" for="twitter-account-input">Twitter</label>
							<input
								v-model="myWorldModel.twitterLink"
								type="text"
								class="form-control inter-font-16 myworld-input"
								id="twitter-account-input"
								placeholder="-" />
							<div
								v-show="
									myWorldModel.twitterLink !== null &&
									myWorldModel.twitterLink !== '' &&
									!isValidLink(myWorldModel.twitterLink)
								"
								class="alert alert-danger mt-1"
								role="alert"
								v-cloak>
								Please enter a valid link. A valid link will look something like this:<br>
								<a href = "https://twitter.com/LiveSwitchInc/" target="_blank">https://twitter.com/LiveSwitchInc/</a>
							</div>
						</div>
						<div class="form-group mt-3">
							<label class="myworldlabels" for="instagram-account-input">Instagram</label>
							<input
								v-model="myWorldModel.instagramLink"
								type="text"
								class="form-control inter-font-16 myworld-input"
								id="instagram-account-input"
								placeholder="-" />
							<div
								v-show="
									myWorldModel.instagramLink !== null &&
									myWorldModel.instagramLink !== '' &&
									!isValidLink(myWorldModel.instagramLink)
								"
								class="alert alert-danger mt-1"
								role="alert"
								v-cloak>
								Please enter a valid link. A valid link will look something like this:<br>
								<a href = "https://www.instagram.com/liveswitchinc/" target="_blank">https://www.instagram.com/liveswitchinc/</a>
							</div>
						</div>
						<div class="form-group mt-3">
							<label class="myworldlabels" for="website-account-input">Website</label>
							<input
								v-model="myWorldModel.websiteLink"
								type="text"
								class="form-control inter-font-16 myworld-input"
								id="website-account-input"
								placeholder="-" />
							<div
								v-show="
									myWorldModel.websiteLink !== null &&
									myWorldModel.websiteLink !== '' &&
									!isValidLink(myWorldModel.websiteLink)
								"
								class="alert alert-danger mt-1"
								role="alert"
								v-cloak>
								Please enter a valid link. A valid link will look something like this:<br>
								<a href = "https://www.liveswitch.com/" target="_blank">https://www.liveswitch.com/</a>
							</div>
						</div>
						<div class="form-group text-center mt-4">
							<div v-show="updatedSuccessfully" class="alert alert-success" role="alert">
								<h5 class="alert-heading">MyWorld updated successfully.</h5>
							</div>
							<button
								v-if="!isUpdating"
								class="btn btn-primary btn-lg btn-my-world-update-lg"
								@click="update()"
								v-bind:disabled="isUpdating || !dirty || !valid">
								Update
							</button>
							<button
								v-else
								class="btn btn-primary btn-lg btn-my-world-update-lg"
								type="button"
								v-bind:disabled="isUpdating">
								<span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div
				class="col-12 col-sm-12 col-md-6 col-lg-6 my-world-edit-col"
				id="my-world-right-panel"
				ref="myWorldPreview"
				v-show="!useHelpers().isMobileBrowser() || currentNavbarItem == 1">
				<div class="card myworld-main-card myworld-main-card-preview">
					<div class="card-body">
						<h4 class="inter_medium">How People Will See It</h4>
						<label class="inter-grey-text" v-if="!windowWidthIsLess800"
							>Enter your information on the left panel to display it here.</label
						>
						<label class="inter-grey-text" v-else
							>Enter your information in "Edit World" to display it here.</label
						>
						<br />
						<h4 class="inter_medium mt-4" style="word-break: break-all">
							{{ myWorldModel.name }}
						</h4>

						<div v-show="!isEnteredAnyInformation" class="no-info">
							You have no information available to display.<br />
						</div>
						<div class="form-group mw-info-view inter_medium" v-show="!allAboutYouEmpty">
							<div
								class="input-group mw"
								v-show="myWorldModel.location !== '' && myWorldModel.location !== null">
								<div class="container row">
									<div class="col-2 col-sm-1">
										<i class="fa fa-home mw-icon"></i>
									</div>
									<div class="col pl-1">
										<h5 class="mt-2">{{ myWorldModel.location }}</h5>
									</div>
								</div>
							</div>
							<div
								class="input-group mw"
								v-show="myWorldModel.school !== '' && myWorldModel.school !== null">
								<div class="container row">
									<div class="col-2 col-sm-1">
										<i class="fas fa-graduation-cap mw-icon"></i>
									</div>
									<div class="col pl-1">
										<h5 class="mt-2">{{ myWorldModel.school }}</h5>
									</div>
								</div>
							</div>
							<div class="input-group mw" v-show="myWorldModel.work !== '' && myWorldModel.work !== null">
								<div class="container row">
									<div class="col-2 col-sm-1">
										<i class="fas fa-suitcase mw-icon"></i>
									</div>
									<div class="col pl-1">
										<h5 class="mt-2">{{ myWorldModel.work }}</h5>
									</div>
								</div>
							</div>
							<div
								class="input-group mw"
								v-show="myWorldModel.jobTitle !== '' && myWorldModel.jobTitle !== null">
								<div class="container row">
									<div class="col-2 col-sm-1">
										<i class="fas fa-id-card-alt mw-icon"></i>
									</div>
									<div class="col pl-1">
										<h5 class="mt-2">{{ myWorldModel.jobTitle }}</h5>
									</div>
								</div>
							</div>
						</div>
						<!-- Photo Preview -->
						<div class="mw-info-view mt-4" v-show="photosPreview.length > 0">
							<div class="row mt-1 photo-row preview-row">
								<div v-for="(photo, index) in photosPreview" class="photo-container preview-container">
									<div class="myworld-image-div">
										<img
											class="myworld-image"
											v-bind:src="photo.imageUrl"
											v-bind:title="photo.pinName"
											v-if="photo.imageUrl !== ''" />
									</div>
								</div>
							</div>
						</div>

						<div v-for="fact in myWorldModel.funFacts" v-show="myWorldModel.funFacts.length > 0">
							<div class="form-group mw-info-view mt-4" v-if="fact.pinContent != ''">
								<div class="input-group mw fact-preview">
									<h5 class="fun-fact-question ml-3 mt-2 inter_medium">
										{{ fact.pinName }}
									</h5>
									<label class="myworldviewlabels ml-3 inter-grey-text" id="fun-fact">
										{{ fact.pinContent }}
									</label>
								</div>
							</div>
						</div>
						<div
							class="form-group mw-info-view inter_medium mt-4 social-media-info"
							v-show="!allSocialMediaIsEmpty">
							<div class="mw ml-3 mt-2">
								<h5 class="">Find me on social media and on the web</h5>
								<div class="social-icon-container">
									<a
										v-show="myWorldModel.linkedinLink !== '' && myWorldModel.linkedinLink !== null"
										v-bind:href="addHttp(myWorldModel.linkedinLink)"
										class="social-media-link"
										target="_blank">
										<i
											id="linked-in-icon"
											class="myworld-social-icon mic-on fab fa-linkedin-in mr-4"></i>
									</a>
									<a
										v-show="myWorldModel.facebookLink !== '' && myWorldModel.facebookLink !== null"
										v-bind:href="addHttp(myWorldModel.facebookLink)"
										class="social-media-link"
										target="_blank">
										<i
											id="facebook-icon"
											class="myworld-social-icon mic-on fab fa-facebook-f mr-4"></i>
									</a>
									<a
										v-show="myWorldModel.twitterLink !== '' && myWorldModel.twitterLink !== null"
										v-bind:href="addHttp(myWorldModel.twitterLink)"
										class="social-media-link"
										target="_blank">
										<i id="twitter-icon" class="myworld-social-icon mic-on fab fa-twitter mr-4"></i>
									</a>
									<a
										v-show="
											myWorldModel.instagramLink !== '' && myWorldModel.instagramLink !== null
										"
										v-bind:href="addHttp(myWorldModel.instagramLink)"
										class="social-media-link"
										target="_blank">
										<i
											id="instagram-icon"
											class="myworld-social-icon mic-on fab fa-instagram mr-4"></i>
									</a>
									<a
										v-show="myWorldModel.websiteLink !== '' && myWorldModel.websiteLink !== null"
										v-bind:href="addHttp(myWorldModel.websiteLink)"
										class="social-media-link"
										target="_blank">
										<i id="website-icon" class="myworld-social-icon mic-on fas fa-globe mr-4"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" tabindex="-1" role="dialog" :class="{ show: editingImage }">
		<div class="modal-dialog modal-xl modal-edit-image-dialog" role="document">
			<div class="modal-content modal-edit-image">
				<div class="modal-header justify-content-start no-border">
					<h5 class="modal-title image-editor-label">Image Editor</h5>
					<div class="d-flex justify-content-end">
						<i class="fal fa-times close" @click="closeImageEditor"></i>
					</div>
				</div>
				<div class="modal-body">
					<div class="row mt-4">
						<div class="col-12 col-xl-6 modal-editor-col">
							<div class="editor-menu">
								<span @click="zoomIn" class="editor-menu-icon-container">
									<i
										id="btn-edit-zoomin"
										class="fas fa-search-plus editor-menu-icon clickable ml-3 mr-4"></i>
								</span>
								<span @click="zoomOut" class="editor-menu-icon-container">
									<i
										id="btn-edit-zoomout"
										class="fas fa-search-minus editor-menu-icon clickable mr-4"></i>
								</span>
								<span @click="rotate" class="editor-menu-icon-container">
									<i
										id="btn-edit-rotate"
										class="fas fa-redo-alt fa-flip-horizontal editor-menu-icon clickable mr-4"></i>
								</span>
								<span @click="deleteImage" class="editor-menu-icon-container">
									<i id="btn-edit-trash" class="fas fa-trash editor-menu-icon clickable mr-4"></i>
								</span>
								<span @click="mirrorVertically" class="editor-menu-icon-container">
									<i
										id="btn-edit-mirror-v"
										class="fas fa-arrows-alt-v editor-menu-icon clickable mr-4"></i>
								</span>
								<span @click="mirrorHorizontally" class="editor-menu-icon-container">
									<i
										id="btn-edit-mirror-h"
										class="fas fa-arrows-alt-h editor-menu-icon clickable mr-4"></i>
								</span>
							</div>
							<div class="img-container mt-2" style="min-height: 370px">
								<img
									v-bind:src="currentImageUrl"
									id="image-editor"
									ref="imageEditor"
									alt="Picture"
									style="max-height: 400px; display: block; max-width: 100%"
									crossorigin="anonymous" />
							</div>
						</div>
						<div class="col-12 col-xl-6 d-flex flex-column position-relative modal-editor-col">
							<h4 class="inter_medium mt-3">Add a description</h4>
							<textarea
								id="myworld-image-title-modal"
								class="form-control myworld-input"
								type="text"
								rows="4"
								placeholder="Here’s me and my family on vacation last month."
								maxlength="200"
								v-model="newImageDescription"></textarea>
							<div
								id="alert-uploading-image"
								class="alert alert-danger mt-3"
								role="alert"
								style="display: none">
								Uploading image is taking too long. Try press Cancel and try again.
							</div>
							<div class="btn-delete-save-cancel">
								<label @click="deleteImage" class="delete-image-label mt-3 color-red clickable"
									>Delete image</label
								>
								<div class="row mt-3 button-editor">
									<div class="col-12">
										<button
											id="btn-edit-image-save"
											type="button"
											class="btn btn-primary float-right"
											@click="saveImage">
											<span id="image-saving" v-show="!uploadingImage">Save</span>
											<span id="image-not-saving" v-show="uploadingImage">
												<span
													class="spinner-border spinner-border-sm"
													role="status"
													aria-hidden="true"></span>
												Saving...
											</span>
										</button>
										<button
											id="btn-edit-image-cancel"
											type="button"
											class="btn ls-btn-grey float-right mr-4"
											@click="closeImageEditor">
											Cancel
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import "cropperjs/dist/cropper.css";
	import {
		onMounted,
		ref,
		watch,
		computed,
		reactive,
		type PropType,
		nextTick,
		type ComponentPublicInstance,
		inject,
	} from "vue";
	import useEventBus from "@/composables/useEventBus";
	import useHelpers from "@/composables/useHelpers";
	import { MyWorldModel, type MyWorldImage } from "@/classes/MyWorldService";
	import type MeetingHandler from "@/classes/MeetingHandler";
	import Cropper from "cropperjs";
	import { v4 as uuid } from "uuid";
	import type IMyWorldService from "@/classes/MyWorldService";
	import Multiselect from "@vueform/multiselect";
	import { SeverityLevel } from "@microsoft/applicationinsights-common";
	import useLocalStorage from "@/composables/useLocalStorage";
	import { InjectionKeyAppInsights, InjectionKeyMyWorldService } from "@/composables/injectKeys";
	import type { ApplicationInsights } from "@microsoft/applicationinsights-web";

	let myWorld: MyWorldModel = new MyWorldModel();
	const myWorldModel = ref(reactive(myWorld));
	const props = defineProps({
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
	});

	const myWorldService: IMyWorldService = inject(InjectionKeyMyWorldService) as IMyWorldService;
	const CreateOwnQuestionLabel = "+ Create your own question";
	const currentNavbarItem = ref(0);
	const myWorldPreview = ref<ComponentPublicInstance>();
	const dirty = ref(false);
	const isUpdating = ref(false);
	const updatedSuccessfully = ref(false);
	const questions = ref<string[]>([]);
	const newFactQuestion = ref("");
	const newFactAnswer = ref("");
	const showFactModal = ref(false);
	const uploadImage = ref(false);
	const currentImageUrl = ref("");
	const photos = ref<unknown[]>([]);
	const newImageFile = ref("");
	const editingImage = ref(false);
	const imageEditor = ref<HTMLImageElement>();
	const windowWidthIsLess800 = ref(false);
	const errorMessages = ref([]);
	const newImageDescription = ref("");
	const uploadingImage = ref(false);
	const currentImage = ref(undefined as MyWorldImage | undefined);
	const factQuestionsMultisel = ref<Multiselect>();
	const newFactArea = ref<HTMLTextAreaElement>();
	const appInsights = inject(InjectionKeyAppInsights) as ApplicationInsights;
	const eventBus = useEventBus();

	let cropBoxData: Cropper.SetCropBoxDataOptions;
	let canvasData: Cropper.SetCanvasDataOptions;
	let scaleX: number | null = null;
	let scaleY: number | null = null;
	let cropper: Cropper;

	for (var i = 0; i < 6; i++) {
		photos.value.push({
			imageUrl: "",
			pinId: `pin`,
		});
	}

	function isValidFunFact() {
		return newFactQuestion.value && newFactAnswer.value;
	}

	function saveFact() {
		let sortOrder = 0;
		let dummyIndex = -1;
		myWorldModel.value.funFacts.forEach((element, index) => {
			if (element.pinName !== "" && element.pinContent !== "") {
				if (element.sortOrder >= sortOrder) {
					sortOrder = element.sortOrder + 1;
				}
			} else {
				if (dummyIndex === -1) {
					dummyIndex = index;
				}
			}
		});

		const newFact = {
			pinName: newFactQuestion.value,
			pinContent: newFactAnswer.value,
			pinType: "FACT",
			pinHelp: "FACT",
			pinStatus: "ACTIVE",
			sortOrder: sortOrder,
		};

		if (dummyIndex !== -1) {
			myWorldModel.value.funFacts[dummyIndex] = newFact;
		} else {
			myWorldModel.value.funFacts.push(newFact);
		}

		showFactModal.value = false;
		newFactQuestion.value = "";
		newFactAnswer.value = "";
	}

	function deleteFact(index: number) {
		myWorldModel.value.funFacts.splice(index, 1);
		while (model.funFacts.length < 3) {
			model.funFacts.push({
				pinContent: "",
				pinName: "",
				sortOrder: 1000,
			});
		}
	}

	const photosPreview = computed(() => {
		var previewPhotos = myWorldModel.value.images.filter((x) => x.imageUrl != "");
		return previewPhotos;
	});

	const valid = computed(() => {
		return (
			isValidLink(myWorldModel.value.linkedinLink) &&
			isValidLink(myWorldModel.value.facebookLink) &&
			isValidLink(myWorldModel.value.twitterLink) &&
			isValidLink(myWorldModel.value.instagramLink) &&
			isValidLink(myWorldModel.value.websiteLink)
		);
	});

	function isValidLink(url: string): boolean {
		if (!url || url == "") {
			return true;
		}

		const valid = useHelpers().isValidUrl(url);
		return valid;
	}

	function isValidWebsiteLink() {}

	function addHttp(url: string) {
		if (url === null || url === "") {
			return;
		}

		if (url.toLowerCase().includes("http")) {
			return url;
		} else {
			return `https://${url}`;
		}
	}

	function addImage(image: MyWorldImage) {
		uploadImage.value = true;
		currentImage.value = image;
	}

	function openImageSelector() {}

	function editImage(image: MyWorldImage) {
		currentImage.value = image;
		currentImageUrl.value = image.imageUrl;
		newImageDescription.value = image.pinName;
		editingImage.value = true;

		setTimeout(() => {
			if (imageEditor.value) {
				cropper = new Cropper(imageEditor.value, {
					// aspectRatio: 4 / 3,
					autoCropArea: 1,
					checkCrossOrigin: false,

					ready: function () {
						cropper.setCropBoxData(cropBoxData).setCanvasData(canvasData);
						scaleX = -1;
						scaleY = -1;
					},
				});
			}
		}, 50);
	}

	function imageSelected(event: Event) {
		const target = event.target as HTMLInputElement;
		if (target.files && imageEditor.value) {
			var file = target.files[0];
			var imageUrl = URL.createObjectURL(file);
			currentImageUrl.value = imageUrl;
			newImageFile.value = file.name;
			uploadImage.value = false;
			editingImage.value = true;

			setTimeout(() => {
				if (imageEditor.value) {
					cropper = new Cropper(imageEditor.value, {
						// aspectRatio: 4 / 3,
						autoCropArea: 1,

						ready: function () {
							cropper.setCropBoxData(cropBoxData).setCanvasData(canvasData);
							scaleX = -1;
							scaleY = -1;
						},
					});
				}
			}, 250);
		}
	}

	function zoomIn() {
		cropper.zoom(0.1);
	}

	function zoomOut() {
		cropper.zoom(-0.1);
	}

	function rotate() {
		cropper.rotate(-90);
	}

	function crop() {
		cropper.crop();
		cropper.setDragMode("crop");
	}

	function mirrorVertically() {
		cropper.scaleY(scaleY ?? 1);
		if (scaleY) {
			scaleY *= -1;
		}
	}

	function mirrorHorizontally() {
		cropper.scaleX(scaleX ?? 1);
		if (scaleX) {
			scaleX *= -1;
		}
	}

	function deleteImage() {
		closeImageEditor();

		if (currentImage.value) {
			currentImage.value.imageUrl = "";
		}
	}

	function closeImageEditor() {
		currentImageUrl.value = "";
		newImageFile.value = "";
		newImageDescription.value = "";
		uploadImage.value = false;
		editingImage.value = false;

		if (cropper) {
			cropper.destroy();
		}
	}

	function saveImage() {
		uploadingImage.value = true;

		cropper.getCroppedCanvas().toBlob(async (blob) => {
			let description = newImageDescription.value;
			let imageId: string = uuid();
			let form: FormData = new FormData();
			let userAccountId = useLocalStorage().getUserAccountId();
			let imageName = newImageFile.value;

			if (currentImage.value && currentImage.value.imageUrl != "") {
				imageId = currentImage.value.pinId;
				imageName = currentImage.value.imageUrl.substring(currentImage.value.imageUrl.lastIndexOf("/") + 1);
			}

			if (blob) {
				form.append("file", blob, imageName);
			}
			form.append("imageUrl", `users/${userAccountId}/pins/${imageId}.jpg`);

			let response = await myWorldService.uploadImageAsync(form);

			if (response && response.objectUri) {
				let image = myWorldModel.value.images.find((x) => x.pinId == currentImage.value?.pinId);

				if (image) {
					image.imageUrl = response.objectUri;
					image.pinId = imageId;
					image.pinName = description;
				}

				editingImage.value = false;
				currentImageUrl.value = "";
				newImageDescription.value = "";
				newImageFile.value = "";
				cropper.destroy();
				appInsights.trackMetric(
					{
						name: "MyWorldUpdateImage",
						average: 1,
					},
					useHelpers().getLoggingProperties()
				);
			}

			uploadingImage.value = false;
			// TODO: Clear forms
		});
	}

	const allAboutYouEmpty = computed(() => {
		let model = myWorldModel.value;

		return (
			(model.name === null || model.name === "") &&
			(model.location === null || model.location === "") &&
			(model.school === null || model.school === "") &&
			(model.work === null || model.work === "") &&
			(model.jobTitle === null || model.jobTitle === "")
		);
	});

	const allSocialMediaIsEmpty = computed(() => {
		let model = myWorldModel.value;

		return (
			!model.linkedinLink &&
			!model.facebookLink &&
			!model.twitterLink &&
			!model.instagramLink &&
			!model.websiteLink
		);
	});

	const isEnteredAnyInformation = computed(() => {
		return !allAboutYouEmpty.value || !allSocialMediaIsEmpty.value;
	});

	watch(
		() => props.meetingHandler,
		async () => {
			await initialize();
		}
	);

	watch(
		myWorldModel,
		(newModel, oldModel) => {
			dirty.value = true;
		},
		{ deep: true }
	);
	watch(newFactQuestion, async (newFactOpt) => {
		if (newFactOpt === CreateOwnQuestionLabel) {
			// After we picked first info item, we simulate clean action with focus on empty space
			await nextTick();
			newFactQuestion.value = "";
			(
				(factQuestionsMultisel.value?.$el as HTMLElement).querySelector(
					"input.multiselect-search"
				) as HTMLInputElement
			)?.focus();
		} else if (newFactOpt) {
			// Some option selected, we move focus to text area
			newFactArea.value?.focus();
		}
	});

	onMounted(async () => {
		await initialize();

		eventBus.onEvent("signed-in-mid-meeting", async () => {
			await initialize();
		});
	});

	function addNewFact() {
		showFactModal.value = true;
	}

	async function initialize() {
		let userAccountId = useLocalStorage().getUserAccountId();

		try {
			let model = await myWorldService.getAsync(userAccountId).catch((err) => {
				throw Error(err);
			});

			if (model) {
				if (!model.funFacts) {
					model.funFacts = [];
				}

				while (model.funFacts.length < 3) {
					model.funFacts.push({
						pinContent: "",
						pinName: "",
						sortOrder: 1000,
					});
				}
				model.funFacts.sort((a, b) => a.sortOrder - b.sortOrder);

				if (!model.images) {
					model.images = [];
				}

				if (model.images.length < 6) {
					for (var i = model.images.length; i < 6; i++) {
						let newImage: MyWorldImage = {
							imageUrl: "",
							pinId: `new-image-${i}`,
							pinContent: "",
							pinHelp: "",
							pinName: "",
							pinStatus: "NEW_IMAGE",
							pinType: "",
							roomId: uuid(),
							roomPinId: uuid(),
						};

						model.images.push(newImage);
					}
				}

				myWorldModel.value = reactive(model);
			}

			questions.value = [CreateOwnQuestionLabel, ...((await myWorldService.getFunFactQuestions()) ?? [])];
			dirty.value = false;

			(
				(factQuestionsMultisel.value?.$el as HTMLElement).querySelector(
					"input.multiselect-search"
				) as HTMLInputElement
			).maxLength = 100;

			const input = (factQuestionsMultisel.value?.$el as HTMLElement).querySelector(
				"input.multiselect-search"
			) as HTMLInputElement;

			input.onblur = () => {
				factQuestionsMultisel.value.select({ label: input.value, value: input.value });
				console.log(input.value);
			};
		} catch (err: any) {
			appInsights.trackException(
				{
					exception: err,
					id: "MyWorldLoadError",
					severityLevel: SeverityLevel.Error,
				},
				useHelpers().getLoggingProperties("MyWorldLoadError", "Error retrieving MyWorld")
			);

			eventBus.emitEvent("toggle-local-my-world", false);
		}
	}

	async function update() {
		eventBus.emitEvent("loading", "Updating...");
		isUpdating.value = true;

		try {
			myWorldModel.value.userAccountId = useLocalStorage().getUserAccountId();
			myWorldModel.value.funFacts = myWorldModel.value.funFacts.filter(
				(x) => x.pinContent != "" && x.pinName != ""
			);

			myWorldModel.value.images = myWorldModel.value.images.filter((x) => x.imageUrl != "");

			let newModel: any = await myWorldService.updateAsync(myWorldModel.value);

			if (newModel && newModel.length > 0) {
				appInsights.trackMetric(
					{
						name: "MyWorldUpdate",
						average: 1,
					},
					useHelpers().getLoggingProperties()
				);
				console.log("MyWorld Updated");
				await initialize();
			}

			isUpdating.value = false;
			updatedSuccessfully.value = true;
			setTimeout(() => {
				updatedSuccessfully.value = false;
			}, 3500);
			dirty.value = false;
		} catch (err) {
			isUpdating.value = false;
		} finally {
			eventBus.emitEvent("loading-complete");
		}
	}
</script>

<style scoped>
	.world-container {
		color: black;
		width: 100%;
		max-width: 1400px;
		margin: auto;
		height: calc(100vh - 172px);
		overflow: hidden;
		overflow-y: scroll;
	}

	.myworld-main-card {
		border-radius: 15px !important;
		/* box-shadow: 0 50px 77px 0 rgb(176 183 195 / 22%); */
		background-color: #ffffff;
		border-color: #ffffff;
	}

	#my-world-row {
		margin: auto;
	}

	.inter-grey-text {
		font-family: Inter_Medium;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: left;
		color: #8a94a6;
	}

	.btn-my-world-update {
		padding: 0.5em 2em;
		height: 36px;
		border-radius: 10px !important;
		flex-grow: 0;
		background-color: #346ee0;
		margin: 0px;
		font-size: 14px;
		color: white;
		border: none;
		outline: none;
		font-family: "Inter_Medium";
	}

	.btn-my-world-update-lg {
		width: 50%;
		height: 55px;
		border-radius: 10px !important;
		flex-grow: 0;
		background-color: #346ee0;
		margin: 0px;
	}

	.my-world-update-header {
		justify-content: space-between;
		flex-direction: row;
	}

	.myworldlabels {
		font-family: Inter_Medium;
		font-size: 14px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		text-align: left;
		color: #4e5d78;
	}

	.photo-row {
		margin-bottom: 15px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
	}

	.photo-row.preview-row {
		margin-bottom: 0;
	}

	.photo-container {
		margin: 0 auto 12px;
		width: 150px;
		height: 120px;
		cursor: pointer;
    overflow: hidden;
    border-radius: 15px;
	}

	.photo-container.preview-container {
		display: flex;
	}

	.photo-container:hover {
		opacity: 0.8;
	}

	.add-photo-button {
		border-radius: 15px;
		border: dashed 2px #e8e8e8;
		height: 120px;
		width: 150px;
		margin-right: 10px;
		position: relative;
		text-align: center;
		background-color: #fafbfc;
		font-family: Inter_Medium;
		font-size: 14px;
		font-weight: 600;
		color: #323b4b;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.opacity05 {
		opacity: 0.5;
	}

	.mw-info-view {
		border-radius: 15px;
		box-shadow: 0 50px 77px 0 rgb(176 183 195 / 22%);
		border: solid 2px #fafbfc;
		background-color: #ffffff;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		align-content: center;
	}

	.mw {
		align-items: center;
	}

	.mw-icon {
		margin-top: 10px;
	}

	.myworld-social-icon {
		color: #4e5d78;
		font-size: 25px;
	}

	.fun-fact-question,
	.myworldviewlabels,
	.fact-question {
		width: 100%;
		word-break: break-word;
	}

	.no-info {
		font-weight: 500;
	}

	.my-world-mobile-header-container {
		color: white;
		margin-top: 8px;
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 1000;
		background-color: #323b4b;
	}

	.my-world-mobile-header-row {
		display: flex;
		align-items: center;
	}

	.my-world-header {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.5rem;
	}

	.my-world-header .btn-my-world-lg {
		width: 32px;
		height: 32px;
		padding: 6px;
		margin-right: 8px;
		background-color: #346ee0;
		border-radius: 50%;
	}

	.my-world-close {
		display: flex;
		justify-content: center;
	}

	.my-world-mobile-tabs {
		display: flex;
		background-color: #4e5d78;
		margin: 8px 15px;
		border-radius: 10px;
	}

	.social-icon-container {
		display: flex;
		justify-content: center;
	}

	.social-media-link {
		color: #4e5d78;
		font-size: 25px;
		margin: 0 1rem;
	}

	.myworld-delete-fact-button {
		/* position: absolute;
		right: 15px;
		top: 14px;
		font-size: 1em; */
		color: #ff715b;
		margin-top: -6px;
		width: 24px;
	}

	.save-fun-fact-button {
		padding: 0.75em 4em;
		flex-grow: 0;
		border-radius: 10px;
		justify-self: flex-end;
		display: flex;
	}

	.fun-fact-question-title {
		padding-left: 15%;
		padding-right: 15%;
	}

	.fact-question {
		flex: 1;
	}

	.close-fun-fact-modal {
		position: absolute;
		right: 20px;
		top: 20px;
	}

	.modal-lg {
		max-width: 700px;
	}

	.myworld-input {
		flex-grow: 0;
		border-radius: 4px;
		box-shadow: 1px 4px 4px 2px rgb(0 0 0 / 5%);
		border: solid 1px var(--white);
		background-color: #ffffff;
	}

	.upload-icon {
		color: #346ee0;
		right: 10px;
		top: 10px;
	}

	.modal-upload-image {
		width: 450px;
		padding: 1em;
	}

	#btn-upload-image-confirm {
		width: 60%;
		height: 55px;
		flex-grow: 0;
		border-radius: 10px;
		background-color: #346ee0;
	}

	.btn-delete-save-cancel {
		display: flex;
		flex: 1;
		justify-content: flex-end;
		flex-direction: column;
	}

	.image-editor-label {
		flex: 1;
		font-family: Inter_Medium;
		font-size: 24px;
		font-weight: bold;
		color: #323b4b;
	}

	.editor-menu {
		padding: 10px;
		height: 45px;
		flex-grow: 0;
		border-radius: 8px;
		background-color: #323b4b;
	}

	.editor-menu-icon {
		color: white;
		font-size: 20px;
	}

	.editor-menu-icon-container {
		margin: 0 10px;
	}

	.editor-menu-icon-container:first-child {
		margin-left: 6px !important;
	}

	.img-container {
		max-width: 100%;
		width: 100%;
		display: flex;
		justify-content: center;
		border-radius: 10px;
		box-shadow: 0 50px 77px 0 rgb(176 183 195 / 22%);
		background-color: #f0f0f0;
	}

	.delete-image-label {
		width: 101px;
		height: 20px;
		flex-grow: 0;
		font-family: Inter_Medium;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: left;
		color: #f44a4a;
	}

	#btn-edit-image-save {
		width: 180.4px;
		height: 55px;
		flex-grow: 0;
		border-radius: 10px;
		background-color: #346ee0;
	}

	#btn-edit-image-cancel {
		width: 180.4px;
		height: 55px;
		flex-grow: 0;
	}

	.display-none {
		display: none;
	}

	.modal-edit-image-dialog {
		width: 100%;
	}

	.myworld-delete-image-button {
		position: absolute;
		right: 15px;
		top: 14px;
		color: #ff715b;
	}

	.myworld-image {
		max-height: 100%;
		max-width: 100%;
		border-radius: 15px;
	}

	.myworld-image-div {
		position: relative;
	}
	
	.photo-container.preview-container {
		margin: 10px;
		width: 149px;
		height: 110px;
	}

	.photo-container.preview-container .myworld-image {
		width: 149px;
		height: 110px;
		border-radius: 15px;
	}

	.fact-preview {
		padding: 0.5em 1em;
	}

	.my-world-mobile-tabs {
		display: flex;
		background-color: #4e5d78;
		margin: 8px 15px;
		border-radius: 10px;
	}

	.my-world-mobile-tabs .tab.active {
		background-color: #8a94a6;
	}

	.my-world-mobile-tabs .tab {
		padding: 0px;
		text-align: center;
		border-radius: 10px;
	}

	.my-world-mobile-tabs .tab a {
		display: block;
		padding: 10px 15px;
		color: white !important;
		text-decoration: none !important;
	}

	@media (min-width: 1168px) {
		.my-world-mobile-header-row {
			margin-bottom: 12px;
		}

		.my-world-close {
			justify-content: flex-end;
		}
	}

	@media (min-width: 768px) {
		.my-world-mobile-tabs {
			display: none;
		}
	}

	.fa-large {
		font-size: 36px !important;
	}
</style>
