﻿<template>
	<div class="side-panel" v-if="props.meetingHandler.isMeetingJoined" v-show="props.meetingHandler.sidePanelOpen">
		<nav class="side-panel-nav">
			<span class="title-text-24 title" v-if="props.meetingHandler.chatOpen"> Chat </span>
			<span class="title-text-24 title" v-if="props.meetingHandler.usersOpen"> Users </span>
			<i class="fal fa-times close" @click="props.meetingHandler.closeSidePanel()"></i>
		</nav>
		<Chat v-show="props.meetingHandler.chatOpen" :meeting-handler="meetingHandler" />
		<Users v-show="props.meetingHandler.usersOpen" :meeting-handler="meetingHandler" :lobby-handler="lobbyHandler" />
	</div>
</template>

<script setup lang="ts">
	import Chat from "@/components/Chat.vue";
	import Users from "@/components/Users.vue";
	import type { PropType } from "vue";
	import type MeetingHandler from "@/classes/MeetingHandler";
import type LobbyHandler from "@/classes/LobbyHandler";

	const props = defineProps({
		meetingHandler: {
			type: Object as PropType<MeetingHandler>,
			required: true,
		},
		lobbyHandler: {
			type: Object as PropType<LobbyHandler>,
			required: true
		}
	});
</script>

<style scoped>
	.side-panel {
		height: 100%;
		background-color: #fff;
		padding: 0.75em;
		border-radius: 15px;
		display: flex;
		flex-direction: column;
	}

	.side-panel-nav {
		color: black;
		display: flex;
		align-items: center;
		width: 100%;
	}

	.side-panel-nav .title {
		margin-left: 0;
	}

	.side-panel-nav .close {
		justify-self: flex-end;
		margin-right: 0.25em;
		font-size: 24px;
		border-radius: 50%;
		padding: 6px;
		height: 36px;
		width: 36px;
		justify-content: center;
		display: flex;
	}

	.close:hover {
		cursor: pointer;
		color: #444;
		background-color: #aaa !important;
	}

	.title-text-24 {
		flex: 1;
		font-family: "Inter_Medium";
		font-size: 24px;
		font-weight: normal;
		padding-left: 0.5em;
	}

	@media (min-width: 320px) and (max-width: 767px), (orientation: landscape) and (max-height: 420px) {
		.side-panel {
			border-radius: 0;
		}
	}
</style>
